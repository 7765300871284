import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import './App.css';
import LoginPage from './pages/LoginPage';
import WithHeaderFooterLayout from './layouts/WithHeaderFooterLayout';
import { Callback } from './components/auth/Callback';
import { Logout } from './components/auth/Logout';
import { LogoutCallback } from './components/auth/LogoutCallback';
import { SilentRenew } from './components/auth/SilentRenew';
import { PrivateRoute } from './routes/PrivateRoute';
import React, { useEffect } from 'react';
import HomePage from './pages/HomePage';
import useToken from './useToken';
import { RegisterPage } from './pages/Register/Register';
import ConsumerRegister from './pages/Register/ConsumerRegister/ConsumerRegister';
import ProfessionalRegister from './pages/Register/ProfessionalRegister/ProfessionalRegister';
import { AuthContext } from './providers/AuthProvider';
import DoSilentLogin from './pages/DoSilentLogin';
import Wellaverse from './pages/Wellaverse';

const App = () => {
  const { token, setToken } = useToken();
  const authService = React.useContext(AuthContext);
  const isAuthenticated = authService.isAuthenticated();
  const history = useHistory();

  const redirectToHome = () => {
    if (isAuthenticated) {
      history.push("/login");
    }
  }

  return (
    <div>
      <Switch>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <Route path="/dologin" component={DoSilentLogin} />
        <WithHeaderFooterLayout>
          <Route path="/login">
            <LoginPage setToken={setToken} />
          </Route>
          <Route path="/wellaverse">
            <Wellaverse setToken={setToken} />
          </Route>
          <Route exact={true} path="/signin/callback" component={Callback} />
          <Route exact={true} path="/logout" component={Logout} />
          <Route exact={true} path="/logout/callback" component={LogoutCallback} />
          <Route exact={true} path="/silentrenew" component={SilentRenew} />
          <PrivateRoute path="/home" component={HomePage} />
          <Route path="/register" exact component={RegisterPage} />
          <Route path="/register/newcustomer">
            <ProfessionalRegister setToken={setToken} redirectToHome={redirectToHome} />
          </Route>
          <Route path="/register/newConsumer">
            <ConsumerRegister setToken={setToken} redirectToHome={redirectToHome} />
          </Route>
        </WithHeaderFooterLayout>
        <Route>
          <Redirect to="/login" />
        </Route>
      </Switch>
    </div>
  )
}

export default App;