import React, { Component } from "react";
import '../ProfessionalRegister/ProfessionalRegister.scoped.scss';

export default class Step2AccountProfessionalOccupationDetailsForm extends Component {
    render() {
        return (
            <div
                id="account-professional-occupation-step"
                className="account-step"
            >
                <div className="row account-step-back">
                    <div className="col-xs-12">
                        <a onClick={this.props.backHandler}>
                            <span className="glyphicon glyphicon-chevron-left" />
                            BACK
                        </a>
                    </div>
                </div>
                <h2>Choose your professional type</h2>
                <div className="step-progress">
                    <div className="step-progress-label">
                        <span className="text">STEP</span>
                        <span className="number">
                            <span className="active">2</span> / { this.props.registerFormType == "1" ? "7" : "5"}
                        </span>
                    </div>
                    <ul className="step-progress-bar">
                        <li className="done" />
                        <li className="active" />
                        <li className="" />
                        <li className="" />
                        <li className="" />
                        <li className="" />
                        <li className="" />
                    </ul>
                </div>
                <input
                    id="userType"
                    name="userType"
                    type="hidden"
                    defaultValue=""
                />
                <div className="row">
                    <div className="col-xs-12">
                        <div
                            className="occupation"
                            data-nextstepid="account-professional-detail-step"
                            data-detail="#occupation-salon-owner"
                            data-usertype="SALON_OWNER"
                            onClick={this.props.nextHandler}
                        >
                            <div className="occupation-icon">
                                <div className="occupation-icon__inner">
                                    <i className="nc-icon-salon-owner" />
                                </div>
                            </div>
                            <div className="occupation-text">
                                <h3>Salon Owner ordering for multiple stylists</h3>
                                <p>
                                    I own my hair salon and/or order for multiple
                                    stylists
                                </p>
                            </div>
                            <div className="occupation-next">
                                <span className="glyphicon glyphicon-chevron-right" />
                            </div>
                        </div>
                        <div
                            className="occupation"
                            data-nextstepid="account-professional-detail-step"
                            data-detail="#occupation-independent"
                            data-usertype="INDEPENDENT_STYLIST"
                            onClick={this.props.nextHandler}
                        >
                            <div className="occupation-icon">
                                <div className="occupation-icon__inner">
                                    <i className="nc-icon-independent" />
                                </div>
                            </div>
                            <div className="occupation-text">
                                <h3>
                                    Independent Stylist ordering for my own
                                    studio/suite
                                </h3>
                                <p>
                                    I am an Independent Hairstylist and order my own
                                    products for professional use
                                </p>
                            </div>
                            <div className="occupation-next">
                                <span className="glyphicon glyphicon-chevron-right" />
                            </div>
                        </div>
                        <div
                            className="occupation"
                            data-nextstepid="account-professional-detail-step"
                            data-detail="#occupation-student"
                            data-usertype="COSMETOLOGY_STUDENT"
                            onClick={this.props.nextHandler}
                        >
                            <div className="occupation-icon">
                                <div className="occupation-icon__inner">
                                    <i className="nc-icon-student" />
                                </div>
                            </div>
                            <div className="occupation-text">
                                <h3>Cosmetology Student</h3>
                                <p>
                                    I am a cosmetology student ordering products for
                                    educational purposes
                                </p>
                            </div>
                            <div className="occupation-next">
                                <span className="glyphicon glyphicon-chevron-right" />
                            </div>
                        </div>
                        <div
                            className="occupation"
                            data-nextstepid="account-professional-detail-step"
                            data-detail="#occupation-school"
                            data-usertype="SCHOOL"
                            onClick={this.props.nextHandler}
                        >
                            <div className="occupation-icon">
                                <div className="occupation-icon__inner">
                                    <i className="nc-icon-school" />
                                </div>
                            </div>
                            <div className="occupation-text">
                                <h3>Cosmetology School</h3>
                                <p>
                                    Institutions specializing in the study and
                                    application of beauty treatment to the hair, skin
                                    and nails.
                                </p>
                            </div>
                            <div className="occupation-next">
                                <span className="glyphicon glyphicon-chevron-right" />
                            </div>
                        </div>
                        <div
                            className="occupation"
                            data-nextstepid="account-professional-detail-step"
                            data-detail="#occupation-nail-tech"
                            data-usertype="NAIL_TECHNICIAN"
                            onClick={this.props.nextHandler}
                        >
                            <div className="occupation-icon">
                                <div className="occupation-icon__inner">
                                    <i className="nc-icon-nail-tech" />
                                </div>
                            </div>
                            <div className="occupation-text">
                                <h3>Nail Technician</h3>
                                <p>
                                    I am a Nail Technician and order my own products
                                    for professional use
                                </p>
                            </div>
                            <div className="occupation-next">
                                <span className="glyphicon glyphicon-chevron-right" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
