import React from 'react';
import styles from './WithHeaderFooterLayout.module.css';
import Header from '../pages/Header/Header';
import Footer from '../pages/Footer/Footer';

function WithHeaderFooterLayout({ children }) {
  return (
    <div>
      <Header />
      <div className={styles["with-header-footer-container"]}>
        {children}
      </div>
      <Footer />
    </div>
  );
}

export default WithHeaderFooterLayout;
