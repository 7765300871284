import React, { useState } from "react";
import { AuthContext } from "../providers/AuthProvider";
import WelcomePage from "./WelcomePage";

function HomePage() {
    const [userId, setUserId] = useState("");
    const [preferedUsername, setPreferedUsername] = useState("");
    const [fullname, setFullname] = useState("");
    const [email, setEmail] = useState("");
    const [emailVerified, setEmailVerified] = useState(false);
    const authService = React.useContext(AuthContext);
    authService.getUser().then((userData) => {
        setUserId(userData['profile']['sub']);
        setPreferedUsername(userData['profile']['preferred_username']);
        setFullname(userData['profile']['name']);
        setEmail(userData['profile']['email']);
        setEmailVerified(userData['profile']['email_verified']);
    });

    return (
        <div>
        <div className="container">
            <table className="table table-sm">
                <thead>
                    <tr>
                        <th colSpan={2}>You are Loggedin as: {preferedUsername}</th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                        <td>User ID:</td>
                        <td>{userId}</td>
                    </tr>
                    <tr>
                        <td>Full Name:</td>
                        <td>{fullname}</td>
                    </tr>
                    <tr>
                        <td>Email Address:</td>
                        <td>{email}</td>
                    </tr>
                    <tr>
                        <td>Is Email Verified?</td>
                        <td>{emailVerified ? "Yes" : "No"}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <WelcomePage />
        </div>
    );
}

export default HomePage;
