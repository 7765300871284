import { IDENTITY_CONFIG } from "../Constants"; // METADATA_OIDC
import { UserManager, WebStorageStateStore, Log } from "oidc-client";

export default class AuthService {
  UserManager;

  constructor() {
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    });

    // Logger
    Log.logger = console;
    Log.level = Log.ERROR;

    this.UserManager.events.addUserLoaded((user) => {
      if (window.location.href.indexOf("signin/callback") !== -1) {
        this.navigateToScreen();
      }
    });

    this.UserManager.events.addSilentRenewError((e) => {
      console.log("silent renew error", e.message);
    });

    this.UserManager.events.addAccessTokenExpired(() => {
      console.log("token expired");
      //this.signinSilent();
    });

    if (window.location.href.indexOf("logout/callback") !== -1) {
      this.navigateToLogin();
    }

  }

  signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback().then(() => {
      "";
    });
  };

  getUser = async () => {
    try {
      const user = await this.UserManager.getUser();
      if (!user) {
        return await this.UserManager.signinRedirectCallback();
      }
      return user;
    } catch (e) {
      return {};
    }
  };

  parseJwt = (token) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  };

  signinRedirect = () => {
    localStorage.setItem("redirectUri", window.location.pathname);
    this.UserManager.signinRedirect({});
  };

  signinRedirectIdP = (idp) => {
    localStorage.setItem("redirectUri", window.location.pathname);
    console.log(idp);
    this.UserManager.signinRedirect({
      extraQueryParams: {
        "kc_idp_hint": idp
      },
    });
  };

  navigateToScreen = () => {
    window.location.replace("/home");
  };

  navigateToLogin = () => {
    window.location.replace("/login");
  };

  isAuthenticated = () => {
    const oidcStorage = JSON.parse(
      localStorage.getItem(
        `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
      )
    );

    return !!oidcStorage && !!oidcStorage.access_token;
  };

  getAccessToken = () => {
    const oidcStorage = JSON.parse(
      localStorage.getItem(
        `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
      )
    );

    return oidcStorage && oidcStorage.access_token ? oidcStorage.access_token : "";
  }

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then((user) => {
        return true;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  returnSigninSilent = () => {
    return this.UserManager.signinSilent();
  };

  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback();
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  logout = () => {
    this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem("id_token"),
    });
    this.UserManager.clearStaleState();
/*    localStorage.clear();
    this.UserManager.clearStaleState();
    window.location.href = window.location.protocol + "//" + window.location.host;*/
  };

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      window.location.replace(process.env.REACT_APP_PUBLIC_URL);
    });
    this.UserManager.clearStaleState();
  };

  isAuthorized = () => {
    return true;
  }
}
