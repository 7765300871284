import React, { Component } from "react";
import '../ProfessionalRegister/ProfessionalRegister.scoped.scss';

export default class Step7AccountPasswordForm extends Component {
    constructor() {
        super();
        this.state = {
            name: "React",
            pass: ""
        }
    }

    render() {
        return (
            <div
                id="account-password-finish-step"
                className="account-step"
            >
                <div className="row account-step-back">
                    <div className="col-xs-12">
                        <a onClick={this.props.backHandler}>
                            <span className="glyphicon glyphicon-chevron-left" />
                            BACK
                        </a>
                    </div>
                </div>
                <h2>Password</h2>
                <div className="step-progress">
                    <div className="step-progress-label">
                        <span className="text">STEP</span>
                        <span className="number">
                            <span className="active">{ this.props.registerFormType == "1" ? "7" : "5"}</span> / { this.props.registerFormType == "1" ? "7" : "5"}
                        </span>
                    </div>
                    <ul className="step-progress-bar">
                        <li className="done" />
                        { this.props.registerFormType == "1" ? <li className="done" /> : ""}
                        { this.props.registerFormType == "1" ? <li className="done" /> : ""}
                        <li className="done" />
                        <li className="done" />
                        <li className="done" />
                        <li className="active" />
                    </ul>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <div
                            className="password-check js-password-check"
                            style={{ background: "#FAFAFA", padding: 20 }}
                        >
                            <div className="password-check-headline">
                                <i className="nc-icon-shield-keyhole" />
                                <span className="password-check-headline-text">
                                    Safe Password Check
                                </span>
                            </div>
                            <div className="row">
                                <div
                                    className="col-xs-6 col-md-4 password-check-item js-password-check-item"
                                    data-type="uppercase"
                                >
                                    <div className="password-check-item__inner">
                                        <i className="nc-icon-password-checkmark" style={{color: (/[A-Z]/.test(this.state.pass) ? "#198754" : "")}} />
                                        <span className="text">
                                            <span className="js-amount">1</span> Uppercase
                                            letter
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className="col-xs-6 col-md-4 password-check-item js-password-check-item"
                                    data-type="number"
                                >
                                    <div className="password-check-item__inner">
                                        <i className="nc-icon-password-checkmark" style={{color: (/[0-9]/.test(this.state.pass) ? "#198754" : "")}} />
                                        <span className="text">
                                            <span className="js-amount">1</span> Number
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className="col-xs-6 col-md-4 password-check-item js-password-check-item"
                                    data-type="length"
                                >
                                    <div className="password-check-item__inner">
                                        <i className="nc-icon-password-checkmark" style={{color: (this.state.pass.length >= 8 ? "#198754" : "")}} />
                                        <span className="text">
                                            <span className="js-amount">8</span>{" "}
                                            Characters
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className="col-xs-6 col-md-4 password-check-item js-password-check-item"
                                    data-type="lowercase"
                                >
                                    <div className="password-check-item__inner">
                                        <i className="nc-icon-password-checkmark" style={{color: (/[a-z]/.test(this.state.pass) ? "#198754" : "")}} />
                                        <span className="text">
                                            <span className="js-amount">1</span> Lowercase
                                            letter
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className="col-xs-6 col-md-4 password-check-item js-password-check-item"
                                    data-type="special"
                                >
                                    <div className="password-check-item__inner">
                                        <i className="nc-icon-password-checkmark" style={{color: (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(this.state.pass) ? "#198754" : "")}} />
                                        <span className="text">
                                            <span className="js-amount">1</span> Special
                                            Character
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-md-10">
                        <div className="form-group">
                            <div className="password">
                                <label
                                    className="control-label "
                                    htmlFor="password"
                                >
                                    Password<span className="mandatory">*</span>
                                </label>
                                <input
                                    id="password"
                                    name="pwd"
                                    className="js-password-input form-control js-required"
                                    type="password"
                                    required="required"
                                    onChange={(e) => {this.props.setFormData("password", e.target.value); this.setState({"pass": e.target.value})}}
                                    />
                                <div className="info-block">
                                    <i className="nc-icon-info-circle" />
                                    Please ensure your password matches the above
                                    minimum requirements.
                                </div>
                            </div>
                            <div className="help-block with-errors" />
                        </div>
                    </div>
                </div>
                <div className="row account-step-buttons">
                    <span className="hidden-xs hidden-sm" style={{width: 'auto'}}>
                        <div className="col-xs-12 col-md-auto">
                            <a
                                onClick={this.props.backHandler}
                                className="btn btn-default custom-cancel-btn btn-block account-btn-prev"
                            >
                                BACK
                            </a>
                        </div>
                    </span>
                    <div className="col-xs-12 col-md-auto">
                        <button
                            type="button"
                            onClick={this.props.onSubmit}
                            className="btn btn-primary btn-block account-btn-finish"
                            disabled={(/[A-Z]/.test(this.state.pass) && /[0-9]/.test(this.state.pass) && this.state.pass.length >= 8 && /[a-z]/.test(this.state.pass) && /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(this.state.pass)) ? "": "disabled"}
                            data-validate="false"
                        >
                            CREATE MY ACCOUNT
                        </button>
                    </div>
                    <div className="col-xs-12 col-md-auto visible-xs visible-sm">
                        <a
                            onClick={this.props.backHandler}
                            className="btn btn-default custom-cancel-btn btn-block account-btn-prev"
                        >
                            BACK
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}