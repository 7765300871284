import axios from "axios";
import Constants from "../Constants";

class RestService {
    instance;
    constructor() {
        this.instance = axios.create({
            baseURL: Constants.API_URL,
            timeout: 5000
        });
    }

    registerUser(formData) {
        return this.instance.post(Constants.API_URL + "/register", formData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        /*var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify(formData);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        return fetch(Constants.API_URL + "/register", requestOptions);*/
    }
}

const singletonRestServiceInstance = new RestService();

Object.freeze(singletonRestServiceInstance);

export default singletonRestServiceInstance;