import React, { Component } from "react";
import '../ProfessionalRegister/ProfessionalRegister.scoped.scss';

export default class Step6AccountProfessionalAgreementForm extends Component {
    render() {
        return (
            <div
                id="account-professional-agreement-step"
                className="account-step"
            >
                <div className="row account-step-back">
                    <div className="col-xs-12">
                        <a onClick={this.props.backHandler}>
                            <span className="glyphicon glyphicon-chevron-left" />
                            BACK
                        </a>
                    </div>
                </div>
                <h2>Professional Agreement</h2>
                <div className="step-progress">
                    <div className="step-progress-label">
                        <span className="text">STEP</span>
                        <span className="number">
                            <span className="active">{ this.props.registerFormType == "1" ? "6" : "4"}</span> / { this.props.registerFormType == "1" ? "7" : "5"}
                        </span>
                    </div>
                    <ul className="step-progress-bar">
                        <li className="done" />
                        { this.props.registerFormType == "1" ? <li className="done" /> : ""}
                        { this.props.registerFormType == "1" ? <li className="done" /> : ""}
                        <li className="done" />
                        <li className="done" />
                        <li className="active" />
                        <li className="" />
                    </ul>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <div style={{ display: "none" }}>
                            <div
                                id="professional-customer-contract-popup"
                                className="account-section-register-panel professional-customer-contract-popup"
                            >
                                <div className="professional-customer-contract-popup-headline">
                                    PROFESSIONAL CUSTOMER CONTRACT
                                </div>
                                <div className="professional-customer-contract-popup-content">
                                    <ul>
                                        <li>
                                            The Wella Corporation (“Wella”) distributes
                                            and sells high quality lines of professional
                                            hair care, nail care, skin care, cosmetic,
                                            makeup, and bath accessories, including Wella,
                                            Sebastian, Nioxin, Kadus Professional, System
                                            Professional and OPI (“Professional Beauty
                                            Products”). The undersigned professional
                                            customer (“Professional Customer”) wishes to
                                            purchase Professional Beauty Products from
                                            Wella and Wella is willing to sell those
                                            Professional Beauty Products to Professional
                                            Customer provided that Professional Customer
                                            agrees to the terms and conditions stated
                                            herein. Wella and Professional Customer enter
                                            into this contract (“Contract“) to establish
                                            the terms and conditions upon which
                                            Professional Beauty Products will be sold by
                                            Wella to Professional Customer.
                                        </li>
                                        <br />
                                    </ul>
                                    <p>
                                        NOW THEREFORE, Wella and Professional Customer
                                        agree as follows:
                                    </p>
                                    <br />
                                    <ul>
                                        <li>
                                            1. This Contract represents the entire
                                            agreement between the parties concerning
                                            Wella’s sale and the Professional Customer’s
                                            purchase and sale or use of the Professional
                                            Beauty Products and replaces and supersedes
                                            any prior contract or agreement between Wella
                                            or its affiliates and Professional Customer
                                            governing the terms and conditions for
                                            purchase and sale or use of the Professional
                                            Beauty Products covered by this Contract. For
                                            greater certainty, this Contract does not
                                            terminate or amend in any manner any contract
                                            regarding the provision by Wella to a
                                            Professional Customer of financing for the
                                            purchase price of beauty equipment, the
                                            repayment thereof, the assignment of discounts
                                            by the Professional Customer to Wella as
                                            security therefore and other related matters,
                                            each of which contracts (and all terms and
                                            conditions thereof) remain in full force and
                                            effect. Wella may specify additional terms and
                                            conditions of sale on invoices issued to
                                            Professional Customer; in the event of any
                                            conflict between invoice terms and this
                                            Contract, the language of this Contract shall
                                            govern.
                                        </li>
                                        <li>
                                            2.Wella may from time to time re-designate or
                                            introduce products or brands as Professional
                                            Beauty Products, in its sole discretion,
                                            without need to amend this Contract but which
                                            will be covered by this Contract.
                                        </li>
                                        <li>
                                            3.All Professional Beauty Products purchased
                                            by Professional Customer from Wella (a) will
                                            be used by Professional Customer on its
                                            premises in connection with providing services
                                            to Professional Customer customers, or (b) in
                                            the case of retail products designed for home
                                            use by Professional Customer customers, will
                                            be sold only on the Professional Customer
                                            floor to legitimate Professional Customer
                                            individual clients in such reasonable
                                            quantities as those clients may purchase for
                                            personal home maintenance use, not to exceed
                                            six (6) units of products per client per
                                            calendar week. Each sale by Professional
                                            Customer must be recorded at the time of sale
                                            by an electronic point of sale device
                                            (typically a cash register). Professional
                                            Customer will not resell Professional Beauty
                                            Products in bulk, nor resell Professional
                                            Beauty Products to any wholesaler, retailer,
                                            diverter or re-distributor of products.
                                        </li>
                                        <li>
                                            4.All Professional Beauty Products, including
                                            hair colouring products, permanent waves, nail
                                            gels and acrylics, and any other products
                                            labeled “for professional use only,” “not for
                                            retail sale” or with similar language, shall
                                            not be sold by Professional Customer to anyone
                                            under any circumstances, but may be used only
                                            by Professional Customer personnel on
                                            Professional Customer premises in connection
                                            with providing professional services to
                                            individual Professional Customer customers.
                                        </li>
                                        <li>
                                            5.Professional Customer will not remove,
                                            obliterate, hide, mask or otherwise tamper
                                            with the batch codes, tracking codes, serial
                                            numbers or other codes on any Professional
                                            Beauty Products.
                                        </li>
                                        <li>
                                            6.Professional Customer is strictly prohibited
                                            from and receives no license to sell
                                            Professional Beauty Products anywhere other
                                            than the at the address indicated below (and
                                            any other Professional Customer locations
                                            under common ownership) to individuals for
                                            their personal and family use. Prohibited
                                            sales methods include, without limitation,
                                            over the internet, via direct mail, telephone,
                                            or catalog.
                                        </li>
                                        <li>
                                            7.All sales and distribution of Professional
                                            Beauty Products not expressly permitted by
                                            this Contract are considered “DIVERSION.” With
                                            each order for Professional Beauty Products
                                            that Professional Customer places from Wella,
                                            Professional Customer represents that the
                                            products being ordered are for Professional
                                            Customer use and sale to individual customers
                                            only as expressly provided for in this
                                            Contract. Professional Customer acknowledges
                                            and agrees that the terms of this Contract are
                                            incorporated into each and every purchase
                                            order placed by Professional Customer to Wella
                                            for Professional Beauty Products. All
                                            Professional Beauty Products are delivered in
                                            reliance upon these agreements and
                                            representations. If Professional Customer
                                            intends to use the Professional Beauty
                                            Products for anything other than Professional
                                            Customer use and resale to individuals for
                                            personal and family use, itis obtaining
                                            Wella’s products by fraud. Professional
                                            Customer understands that DIVERSION damages
                                            Wella’s brands, trademarks, and goodwill and
                                            damages its contractual relations with its
                                            distributors and other customers, and agrees
                                            that such harm cannot be remedied solely with
                                            money damages. If Wella has a subjective good
                                            faith belief that Professional Customer has
                                            diverted or intends to divert any Professional
                                            Beauty Products, Wella may terminate
                                            Professional Customer’s receipt of any or
                                            certain Wella products, at Wella's sole
                                            discretion. Such termination or any other
                                            enforcement action or any other efforts to
                                            halt or prevent DIVERSION or enforce or
                                            exercise any rights under this Contract shall
                                            not be a waiver of any rights or remedies
                                            Wella may possess, and all such rights and
                                            remedies are expressly reserved, including the
                                            ability to initiate civil or criminal legal
                                            proceedings.
                                        </li>
                                        <li>
                                            8.Professional Customer agrees to maintain
                                            contemporaneous records relating to its
                                            receipt, inventory, transfer, shipment or sale
                                            of Professional Beauty Products (collectively
                                            “sales data”) for three (3) years, and to make
                                            all such records available to Wella
                                            immediately upon request in writing or upon
                                            presentation of a Wella representative without
                                            prior notice, so that Wella may investigate
                                            possible DIVERSION or to otherwise facilitate
                                            enforcement of this Contract. Wella will also
                                            have the right to inspect and count all
                                            inventory of Professional Beauty Products,
                                            wherever located. If an audit fails to
                                            establish the authorized sales, use or storage
                                            of Professional Beauty Products, or otherwise
                                            reveals that there has been a breach of this
                                            Contract, Professional Customer shall pay to
                                            Wella any and all costs associated with the
                                            audit or examination of Professional
                                            Customer’s records, including attorney,
                                            investigator and accountant fees.
                                        </li>
                                        <li>
                                            9.In the event that Professional Customer
                                            engages in DIVERSION or in the event that
                                            Wella conducts an audit of Professional
                                            Customer, and the audit findings are
                                            inconclusive as to any suspected DIVERSION
                                            engaged in by Professional Customer, Wella may
                                            place Professional Customer in its Probation
                                            Program. Under a written Amendment in the form
                                            attached to this Contract, Wella's Probation
                                            Program will establish the terms and
                                            conditions under which a Professional Customer
                                            may continue to purchase Professional Beauty
                                            Products, which may include loss of any
                                            discounts, rebates or allowances, loss of
                                            credit terms, increased inventory oversight,
                                            submission of personal credit information,
                                            personal guarantee, posting of a bond or other
                                            collateral/security, and any other
                                            requirements which Wella deems advisable under
                                            the circumstances of the probation.
                                        </li>
                                        <li>
                                            10.If Professional Customer violates or
                                            attempts or threatens to violate this
                                            Contract, directly or indirectly, Wella shall
                                            be entitled to obtain an injunction in any
                                            court of competent jurisdiction against
                                            Professional Customer prohibiting such
                                            violation. As DIVERSION may be difficult to
                                            detect and contract damages from DIVERSION are
                                            difficult to assess, upon Wella’s written
                                            request Professional Customer will pay to
                                            Wella as a genuine pre-estimate of damages and
                                            as contract liquidated damages a sum equal to
                                            the full retail price normally charged by
                                            Professional Customer plus 50% for any of the
                                            Professional Beauty Products sold to
                                            Professional Customer in the last 24 months
                                            prior to termination (or such lesser time
                                            period that Professional Customer has
                                            purchased products from Wella) which are
                                            conclusively presumed to have been resold or
                                            otherwise distributed by Professional Customer
                                            in violation of this Contract, and that
                                            Professional Customer will further pay as
                                            damages whatever costs are incurred by Wella
                                            to recover any such diverted product.
                                            Professional Customer shall have the right to
                                            deduct from this liquidated damages sum any
                                            sales which Professional Customer can prove
                                            (by documentary point of sale evidence) were
                                            made in compliance with this Contract during
                                            the relevant time period. Wella shall have the
                                            right to recover either these liquidated
                                            damages or actual damages for breach of
                                            contract, at its sole discretion. This
                                            liquidated damages provision will not limit or
                                            otherwise affect any tort or other damages or
                                            remedies unless and only to the degree such
                                            damages or remedies are duplicative of
                                            contract damages.
                                        </li>
                                        <li>
                                            11.DIVERSION harms both Professional Customer
                                            and Wella. Accordingly, Professional Customer
                                            must share in the effort to halt DIVERSION,
                                            including reporting any solicitations to
                                            purchase, sell or distribute Professional
                                            Beauty Products in violation of this Contract,
                                            or any other suspicious activity to Wella.
                                            Failure to do so may result in termination or
                                            placement into the Probation Program. If
                                            Professional Customer does report such
                                            solicitations or suspicious activity and that
                                            information leads to a successful civil or
                                            criminal prosecution or a monetary settlement,
                                            Wella will provide Professional Customer with
                                            a monetary reward which, in Wella’s judgment,
                                            is commensurate with the value of the judgment
                                            or settlement. Professional Customer agrees to
                                            cooperate with any investigation or
                                            prosecution by Wella of DIVERSION or attempted
                                            DIVERSION.
                                        </li>
                                        <li>
                                            12.Professional Customer will take appropriate
                                            steps to ensure that all of its employees,
                                            directors, officers, agents, on-site
                                            contractors and shareholders are made aware of
                                            this Contract, and that they comply with it.
                                        </li>
                                        <li>
                                            13.The terms of this Contract shall apply to
                                            all lines of Professional Beauty Products
                                            which Professional Customer currently
                                            purchases from Wella and/or which Professional
                                            Customer may purchase from Wella in the
                                            future. Regardless of whether Professional
                                            Customer ceases to purchase any or all
                                            Professional Beauty Products from Wella or
                                            Wella ceases to sell any or all Professional
                                            Beauty Products to Salon, the obligations of
                                            the Professional Customer under this Contract
                                            continue to apply to all orders of
                                            Professional Beauty Products by Professional
                                            Customer and survive termination of any
                                            supply, sale or purchase obligations or
                                            arrangements or the termination or expiry of
                                            this Contract.
                                        </li>
                                        <li>
                                            14.The parties represent, warrant, and agree
                                            that this Contract does not create a franchise
                                            between parties hereto and that Professional
                                            Customer has not paid a franchise fee,
                                            directly, or indirectly, to Wella. Wella and
                                            ProfessionalCustomer are not and shall not be
                                            considered as joint venturers, partners,
                                            agents, servants, employees, or fiduciaries of
                                            each other and neither shall have the power to
                                            bind or obligate the other, except as set
                                            forth in this Contract. The sole relationship
                                            between Wella and Professional Customer will
                                            be that of independent contracting parties.
                                            Professional Customer acknowledges that Wella
                                            has not made any representation to
                                            Professional Customer other than those set
                                            forth in this Contract. Wella retains the
                                            right not to sell to Professional Customer and
                                            Professional Customer retains the right not to
                                            purchase from Wella.
                                        </li>
                                        <li>
                                            15.This Agreement applies to all Professional
                                            Beauty Products supplied by Wella to the
                                            Professional Customer and shall continue to be
                                            in full force and effect during all periods
                                            that Wella is supplying any such products to
                                            the Professional Customer or that the
                                            Professional Customer has possession or
                                            control of any such products and for a period
                                            extending for two (2) years following the date
                                            of the last supply of any such products by
                                            Wella to the Professional Customer (or two (2)
                                            years following the last date that the
                                            Professional Customer has possession or
                                            control over any such products, if such date
                                            is later than the date of Wella's last supply
                                            of any such products to the Professional
                                            Customer). Provided that Wella may, in its
                                            discretion, at any time, and from time to
                                            time, terminate, in whole or in part, future
                                            supplies of Professional Beauty Products to
                                            Professional Customer immediately upon written
                                            notice. In the event of any such termination,
                                            Professional Customer waives any claim for
                                            damages against Wella and Wella shall have the
                                            right and option to immediately repurchase any
                                            or all Professional Beauty Products sold to
                                            the Professional Customer by Wella which the
                                            Salon has in stock and which are the subject
                                            of any such termination, at the lowest price
                                            that the Professional Customer paid for such
                                            products within the one year prior to
                                            termination, less any discounts, rebates or
                                            allowances applicable to those purchases, and
                                            to destroy all unsaleable products. Products
                                            are unsaleable if cases are opened, the
                                            product has been discontinued, the cases are
                                            damaged, the product containers are damaged or
                                            otherwise determined unsaleable in Wella's
                                            sole discretion.
                                        </li>
                                        <li>
                                            16.Nothing contained in this Contract shall be
                                            construed to prevent or prohibit Wella or its
                                            affiliates from producing, selling, or
                                            distributing a department store line, internet
                                            line, or retail line of products branded as
                                            Wella, Sebastian, Nioxin, or OPI products or
                                            from distributing such products through any
                                            other distributional channel, structure, or
                                            method of any kind.
                                        </li>
                                        <li>
                                            17.Wella is entitled, without restriction, to
                                            delegate, subcontract, transfer or assign this
                                            Agreement or any of its rights or obligations,
                                            whether in whole or in part, without the prior
                                            written consent of Professional Customer.
                                        </li>
                                        <li>
                                            18.All disputes arising out of or in
                                            connection with this Contract, or in respect
                                            of any legal relationship associated with or
                                            derived from this Contract, shall be settled
                                            by arbitration administered by the American
                                            Arbitration Association(“AAA”) in accordance
                                            with its Commercial Arbitration Rules before a
                                            single arbitrator who is knowledgeable about
                                            contract law and business litigation. If the
                                            parties are unable to agree upon an
                                            arbitrator, then AAA shall select the
                                            arbitrator. The place of the arbitration shall
                                            be Los Angeles, California. Judgment on the
                                            award rendered by the arbitrator may be
                                            entered in any court having jurisdiction
                                            thereof. Wella and Professional Customer agree
                                            that each may bring claims against the other
                                            only in an individual capacity and not as a
                                            plaintiff or class member in any purported
                                            class or representative proceeding.
                                        </li>
                                        <li>
                                            19.This Contract shall be governed by and
                                            construed in accordance with the laws of the
                                            State of Delaware, USA. If any provision of
                                            this Contract is found to be unenforceable,
                                            the other provisions of this Contract shall
                                            remain in full force and fully enforceable.
                                        </li>
                                        <li>
                                            20.This Contract may be executed in
                                            counterparts and all such counterparts shall
                                            for all purposes constitute one agreement,
                                            binding on the parties hereto, provided that
                                            each party has executed at least one
                                            counterpart and delivered it to the other
                                            party, and each shall be deemed to be an
                                            original, notwithstanding that all parties are
                                            not signatory to the same counterpart. The
                                            Contract may be executed and delivered by
                                            either of the parties by transmitting to the
                                            other a copy of the Contract (executed by such
                                            delivering party ) by facsimile, telecopies or
                                            computer transmission (or similar means of
                                            electronic communication or transmission) and
                                            delivery in that manner by a party shall be
                                            binding upon such party and deemed to be an
                                            original.
                                        </li>
                                        <li>
                                            21.Except as otherwise provided in this
                                            Contract, this Contract may be amended solely
                                            by a written instrument signed by both of the
                                            parties, and which specifically states that it
                                            constitutes an amendment to this Contract.
                                        </li>
                                        <li>
                                            22.The failure of either party to enforce at
                                            any time any provision of this Contract shall
                                            in no way be construed as a waiver of such
                                            provision and shall not affect the ability of
                                            such party to thereafter enforce each and
                                            every such provision.
                                        </li>
                                    </ul>
                                    <br />
                                    <p>EMPLOYEE CONTRACT</p>
                                    <br />
                                    <ul>
                                        <li>
                                            I understand that as a Wella
                                            Company&nbsp;employee, I have the benefit of
                                            purchasing available products from
                                            wella.professionalstore.com at an employee
                                            discount and am bound to the guidelines
                                            defined in the Professional Customer Contract
                                            and applicable terms and conditions. I agree
                                            not to re-sell any items purchased and
                                            understand that any such action can lead to
                                            disciplinary action up to and including
                                            termination of my employment.&nbsp; Wella
                                            Company reserves the right to change or
                                            suspend this benefit at any time including the
                                            level of discount &amp; which products are
                                            offered for purchase. If my employment at
                                            Wella Company ends, I will no longer have
                                            employee access to
                                            wella.professionalstore.com.
                                        </li>
                                    </ul>
                                    <br />
                                    <p>STUDENT CONTRACT</p>
                                    <br />
                                    <ul>
                                        <li>
                                            I certify that I am currently enrolled as a
                                            cosmetology student and understand that I need
                                            to email a picture of my valid cosmetology
                                            student ID to the wella.professionalstore.com
                                            team to purchase professional salon products
                                            from wella.professionalstore.com at a student
                                            discount. After 12 months from today, if I am
                                            still enrolled in cosmetology school, I will
                                            need to resubmit a picture of my valid
                                            cosmetology student ID to maintain my student
                                            discount on wella.professionalstore.com. If no
                                            longer a student, I realize I must submit my
                                            cosmetology license information to access
                                            special graduation promotions and offers on
                                            wella.professionalstore.com. Student discounts
                                            on wella.professionalstore.com purchases are
                                            only available to cosmetology students.
                                        </li>
                                    </ul>
                                </div>
                                <div className="professional-customer-contract-popup-actions desktop">
                                    <div className="row">
                                        <div className="col-xs-12 col-md-auto">
                                            <a
                                                href="#"
                                                className="btn btn-default custom-cancel-btn btn-block js-professional-customer-contract-popup-disagree"
                                            >
                                                I Disagree
                                            </a>
                                        </div>
                                        <div className="col-xs-12 col-md-auto">
                                            <a
                                                href="#"
                                                className="btn btn-primary btn-block js-professional-customer-contract-popup-agree"
                                            >
                                                I Agree
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="professional-customer-contract-popup-actions mobile">
                                    <div className="row">
                                        <div className="col-xs-12 col-md-auto">
                                            <a
                                                href="#"
                                                className="btn btn-primary btn-block js-professional-customer-contract-popup-agree"
                                            >
                                                I Agree
                                            </a>
                                        </div>
                                        <div className="col-xs-12 col-md-auto">
                                            <a
                                                href="#"
                                                className="btn btn-default custom-cancel-btn btn-block js-professional-customer-contract-popup-disagree"
                                            >
                                                I Disagree
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="professional-customer-contract">
                            <div className="professional-customer-contract__inner">
                                PROFESSIONAL CUSTOMER CONTRACT
                                <br />
                                <br />
                                <ul>
                                    <li>
                                        The Wella Corporation (“Wella”) distributes and
                                        sells high quality lines of professional hair
                                        care, nail care, skin care, cosmetic, makeup,
                                        and bath accessories, including Wella,
                                        Sebastian, Nioxin, Kadus Professional, System
                                        Professional and OPI (“Professional Beauty
                                        Products”). The undersigned professional
                                        customer (“Professional Customer”) wishes to
                                        purchase Professional Beauty Products from Wella
                                        and Wella is willing to sell those Professional
                                        Beauty Products to Professional Customer
                                        provided that Professional Customer agrees to
                                        the terms and conditions stated herein. Wella
                                        and Professional Customer enter into this
                                        contract (“Contract“) to establish the terms and
                                        conditions upon which Professional Beauty
                                        Products will be sold by Wella to Professional
                                        Customer.
                                    </li>
                                    <br />
                                </ul>
                                <p>
                                    NOW THEREFORE, Wella and Professional Customer
                                    agree as follows:
                                </p>
                                <br />
                                <ul>
                                    <li>
                                        1. This Contract represents the entire agreement
                                        between the parties concerning Wella’s sale and
                                        the Professional Customer’s purchase and sale or
                                        use of the Professional Beauty Products and
                                        replaces and supersedes any prior contract or
                                        agreement between Wella or its affiliates and
                                        Professional Customer governing the terms and
                                        conditions for purchase and sale or use of the
                                        Professional Beauty Products covered by this
                                        Contract. For greater certainty, this Contract
                                        does not terminate or amend in any manner any
                                        contract regarding the provision by Wella to a
                                        Professional Customer of financing for the
                                        purchase price of beauty equipment, the
                                        repayment thereof, the assignment of discounts
                                        by the Professional Customer to Wella as
                                        security therefore and other related matters,
                                        each of which contracts (and all terms and
                                        conditions thereof) remain in full force and
                                        effect. Wella may specify additional terms and
                                        conditions of sale on invoices issued to
                                        Professional Customer; in the event of any
                                        conflict between invoice terms and this
                                        Contract, the language of this Contract shall
                                        govern.
                                    </li>
                                    <li>
                                        2.Wella may from time to time re-designate or
                                        introduce products or brands as Professional
                                        Beauty Products, in its sole discretion, without
                                        need to amend this Contract but which will be
                                        covered by this Contract.
                                    </li>
                                    <li>
                                        3.All Professional Beauty Products purchased by
                                        Professional Customer from Wella (a) will be
                                        used by Professional Customer on its premises in
                                        connection with providing services to
                                        Professional Customer customers, or (b) in the
                                        case of retail products designed for home use by
                                        Professional Customer customers, will be sold
                                        only on the Professional Customer floor to
                                        legitimate Professional Customer individual
                                        clients in such reasonable quantities as those
                                        clients may purchase for personal home
                                        maintenance use, not to exceed six (6) units of
                                        products per client per calendar week. Each sale
                                        by Professional Customer must be recorded at the
                                        time of sale by an electronic point of sale
                                        device (typically a cash register). Professional
                                        Customer will not resell Professional Beauty
                                        Products in bulk, nor resell Professional Beauty
                                        Products to any wholesaler, retailer, diverter
                                        or re-distributor of products.
                                    </li>
                                    <li>
                                        4.All Professional Beauty Products, including
                                        hair colouring products, permanent waves, nail
                                        gels and acrylics, and any other products
                                        labeled “for professional use only,” “not for
                                        retail sale” or with similar language, shall not
                                        be sold by Professional Customer to anyone under
                                        any circumstances, but may be used only by
                                        Professional Customer personnel on Professional
                                        Customer premises in connection with providing
                                        professional services to individual Professional
                                        Customer customers.
                                    </li>
                                    <li>
                                        5.Professional Customer will not remove,
                                        obliterate, hide, mask or otherwise tamper with
                                        the batch codes, tracking codes, serial numbers
                                        or other codes on any Professional Beauty
                                        Products.
                                    </li>
                                    <li>
                                        6.Professional Customer is strictly prohibited
                                        from and receives no license to sell
                                        Professional Beauty Products anywhere other than
                                        the at the address indicated below (and any
                                        other Professional Customer locations under
                                        common ownership) to individuals for their
                                        personal and family use. Prohibited sales
                                        methods include, without limitation, over the
                                        internet, via direct mail, telephone, or
                                        catalog.
                                    </li>
                                    <li>
                                        7.All sales and distribution of Professional
                                        Beauty Products not expressly permitted by this
                                        Contract are considered “DIVERSION.” With each
                                        order for Professional Beauty Products that
                                        Professional Customer places from Wella,
                                        Professional Customer represents that the
                                        products being ordered are for Professional
                                        Customer use and sale to individual customers
                                        only as expressly provided for in this Contract.
                                        Professional Customer acknowledges and agrees
                                        that the terms of this Contract are incorporated
                                        into each and every purchase order placed by
                                        Professional Customer to Wella for Professional
                                        Beauty Products. All Professional Beauty
                                        Products are delivered in reliance upon these
                                        agreements and representations. If Professional
                                        Customer intends to use the Professional Beauty
                                        Products for anything other than Professional
                                        Customer use and resale to individuals for
                                        personal and family use, itis obtaining Wella’s
                                        products by fraud. Professional Customer
                                        understands that DIVERSION damages Wella’s
                                        brands, trademarks, and goodwill and damages its
                                        contractual relations with its distributors and
                                        other customers, and agrees that such harm
                                        cannot be remedied solely with money damages. If
                                        Wella has a subjective good faith belief that
                                        Professional Customer has diverted or intends to
                                        divert any Professional Beauty Products, Wella
                                        may terminate Professional Customer’s receipt of
                                        any or certain Wella products, at Wella's sole
                                        discretion. Such termination or any other
                                        enforcement action or any other efforts to halt
                                        or prevent DIVERSION or enforce or exercise any
                                        rights under this Contract shall not be a waiver
                                        of any rights or remedies Wella may possess, and
                                        all such rights and remedies are expressly
                                        reserved, including the ability to initiate
                                        civil or criminal legal proceedings.
                                    </li>
                                    <li>
                                        8.Professional Customer agrees to maintain
                                        contemporaneous records relating to its receipt,
                                        inventory, transfer, shipment or sale of
                                        Professional Beauty Products (collectively
                                        “sales data”) for three (3) years, and to make
                                        all such records available to Wella immediately
                                        upon request in writing or upon presentation of
                                        a Wella representative without prior notice, so
                                        that Wella may investigate possible DIVERSION or
                                        to otherwise facilitate enforcement of this
                                        Contract. Wella will also have the right to
                                        inspect and count all inventory of Professional
                                        Beauty Products, wherever located. If an audit
                                        fails to establish the authorized sales, use or
                                        storage of Professional Beauty Products, or
                                        otherwise reveals that there has been a breach
                                        of this Contract, Professional Customer shall
                                        pay to Wella any and all costs associated with
                                        the audit or examination of Professional
                                        Customer’s records, including attorney,
                                        investigator and accountant fees.
                                    </li>
                                    <li>
                                        9.In the event that Professional Customer
                                        engages in DIVERSION or in the event that Wella
                                        conducts an audit of Professional Customer, and
                                        the audit findings are inconclusive as to any
                                        suspected DIVERSION engaged in by Professional
                                        Customer, Wella may place Professional Customer
                                        in its Probation Program. Under a written
                                        Amendment in the form attached to this Contract,
                                        Wella's Probation Program will establish the
                                        terms and conditions under which a Professional
                                        Customer may continue to purchase Professional
                                        Beauty Products, which may include loss of any
                                        discounts, rebates or allowances, loss of credit
                                        terms, increased inventory oversight, submission
                                        of personal credit information, personal
                                        guarantee, posting of a bond or other
                                        collateral/security, and any other requirements
                                        which Wella deems advisable under the
                                        circumstances of the probation.
                                    </li>
                                    <li>
                                        10.If Professional Customer violates or attempts
                                        or threatens to violate this Contract, directly
                                        or indirectly, Wella shall be entitled to obtain
                                        an injunction in any court of competent
                                        jurisdiction against Professional Customer
                                        prohibiting such violation. As DIVERSION may be
                                        difficult to detect and contract damages from
                                        DIVERSION are difficult to assess, upon Wella’s
                                        written request Professional Customer will pay
                                        to Wella as a genuine pre-estimate of damages
                                        and as contract liquidated damages a sum equal
                                        to the full retail price normally charged by
                                        Professional Customer plus 50% for any of the
                                        Professional Beauty Products sold to
                                        Professional Customer in the last 24 months
                                        prior to termination (or such lesser time period
                                        that Professional Customer has purchased
                                        products from Wella) which are conclusively
                                        presumed to have been resold or otherwise
                                        distributed by Professional Customer in
                                        violation of this Contract, and that
                                        Professional Customer will further pay as
                                        damages whatever costs are incurred by Wella to
                                        recover any such diverted product. Professional
                                        Customer shall have the right to deduct from
                                        this liquidated damages sum any sales which
                                        Professional Customer can prove (by documentary
                                        point of sale evidence) were made in compliance
                                        with this Contract during the relevant time
                                        period. Wella shall have the right to recover
                                        either these liquidated damages or actual
                                        damages for breach of contract, at its sole
                                        discretion. This liquidated damages provision
                                        will not limit or otherwise affect any tort or
                                        other damages or remedies unless and only to the
                                        degree such damages or remedies are duplicative
                                        of contract damages.
                                    </li>
                                    <li>
                                        11.DIVERSION harms both Professional Customer
                                        and Wella. Accordingly, Professional Customer
                                        must share in the effort to halt DIVERSION,
                                        including reporting any solicitations to
                                        purchase, sell or distribute Professional Beauty
                                        Products in violation of this Contract, or any
                                        other suspicious activity to Wella. Failure to
                                        do so may result in termination or placement
                                        into the Probation Program. If Professional
                                        Customer does report such solicitations or
                                        suspicious activity and that information leads
                                        to a successful civil or criminal prosecution or
                                        a monetary settlement, Wella will provide
                                        Professional Customer with a monetary reward
                                        which, in Wella’s judgment, is commensurate with
                                        the value of the judgment or settlement.
                                        Professional Customer agrees to cooperate with
                                        any investigation or prosecution by Wella of
                                        DIVERSION or attempted DIVERSION.
                                    </li>
                                    <li>
                                        12.Professional Customer will take appropriate
                                        steps to ensure that all of its employees,
                                        directors, officers, agents, on-site contractors
                                        and shareholders are made aware of this
                                        Contract, and that they comply with it.
                                    </li>
                                    <li>
                                        13.The terms of this Contract shall apply to all
                                        lines of Professional Beauty Products which
                                        Professional Customer currently purchases from
                                        Wella and/or which Professional Customer may
                                        purchase from Wella in the future. Regardless of
                                        whether Professional Customer ceases to purchase
                                        any or all Professional Beauty Products from
                                        Wella or Wella ceases to sell any or all
                                        Professional Beauty Products to Salon, the
                                        obligations of the Professional Customer under
                                        this Contract continue to apply to all orders of
                                        Professional Beauty Products by Professional
                                        Customer and survive termination of any supply,
                                        sale or purchase obligations or arrangements or
                                        the termination or expiry of this Contract.
                                    </li>
                                    <li>
                                        14.The parties represent, warrant, and agree
                                        that this Contract does not create a franchise
                                        between parties hereto and that Professional
                                        Customer has not paid a franchise fee, directly,
                                        or indirectly, to Wella. Wella and
                                        ProfessionalCustomer are not and shall not be
                                        considered as joint venturers, partners, agents,
                                        servants, employees, or fiduciaries of each
                                        other and neither shall have the power to bind
                                        or obligate the other, except as set forth in
                                        this Contract. The sole relationship between
                                        Wella and Professional Customer will be that of
                                        independent contracting parties. Professional
                                        Customer acknowledges that Wella has not made
                                        any representation to Professional Customer
                                        other than those set forth in this Contract.
                                        Wella retains the right not to sell to
                                        Professional Customer and Professional Customer
                                        retains the right not to purchase from Wella.
                                    </li>
                                    <li>
                                        15.This Agreement applies to all Professional
                                        Beauty Products supplied by Wella to the
                                        Professional Customer and shall continue to be
                                        in full force and effect during all periods that
                                        Wella is supplying any such products to the
                                        Professional Customer or that the Professional
                                        Customer has possession or control of any such
                                        products and for a period extending for two (2)
                                        years following the date of the last supply of
                                        any such products by Wella to the Professional
                                        Customer (or two (2) years following the last
                                        date that the Professional Customer has
                                        possession or control over any such products, if
                                        such date is later than the date of Wella's last
                                        supply of any such products to the Professional
                                        Customer). Provided that Wella may, in its
                                        discretion, at any time, and from time to time,
                                        terminate, in whole or in part, future supplies
                                        of Professional Beauty Products to Professional
                                        Customer immediately upon written notice. In the
                                        event of any such termination, Professional
                                        Customer waives any claim for damages against
                                        Wella and Wella shall have the right and option
                                        to immediately repurchase any or all
                                        Professional Beauty Products sold to the
                                        Professional Customer by Wella which the Salon
                                        has in stock and which are the subject of any
                                        such termination, at the lowest price that the
                                        Professional Customer paid for such products
                                        within the one year prior to termination, less
                                        any discounts, rebates or allowances applicable
                                        to those purchases, and to destroy all
                                        unsaleable products. Products are unsaleable if
                                        cases are opened, the product has been
                                        discontinued, the cases are damaged, the product
                                        containers are damaged or otherwise determined
                                        unsaleable in Wella's sole discretion.
                                    </li>
                                    <li>
                                        16.Nothing contained in this Contract shall be
                                        construed to prevent or prohibit Wella or its
                                        affiliates from producing, selling, or
                                        distributing a department store line, internet
                                        line, or retail line of products branded as
                                        Wella, Sebastian, Nioxin, or OPI products or
                                        from distributing such products through any
                                        other distributional channel, structure, or
                                        method of any kind.
                                    </li>
                                    <li>
                                        17.Wella is entitled, without restriction, to
                                        delegate, subcontract, transfer or assign this
                                        Agreement or any of its rights or obligations,
                                        whether in whole or in part, without the prior
                                        written consent of Professional Customer.
                                    </li>
                                    <li>
                                        18.All disputes arising out of or in connection
                                        with this Contract, or in respect of any legal
                                        relationship associated with or derived from
                                        this Contract, shall be settled by arbitration
                                        administered by the American Arbitration
                                        Association(“AAA”) in accordance with its
                                        Commercial Arbitration Rules before a single
                                        arbitrator who is knowledgeable about contract
                                        law and business litigation. If the parties are
                                        unable to agree upon an arbitrator, then AAA
                                        shall select the arbitrator. The place of the
                                        arbitration shall be Los Angeles, California.
                                        Judgment on the award rendered by the arbitrator
                                        may be entered in any court having jurisdiction
                                        thereof. Wella and Professional Customer agree
                                        that each may bring claims against the other
                                        only in an individual capacity and not as a
                                        plaintiff or class member in any purported class
                                        or representative proceeding.
                                    </li>
                                    <li>
                                        19.This Contract shall be governed by and
                                        construed in accordance with the laws of the
                                        State of Delaware, USA. If any provision of this
                                        Contract is found to be unenforceable, the other
                                        provisions of this Contract shall remain in full
                                        force and fully enforceable.
                                    </li>
                                    <li>
                                        20.This Contract may be executed in counterparts
                                        and all such counterparts shall for all purposes
                                        constitute one agreement, binding on the parties
                                        hereto, provided that each party has executed at
                                        least one counterpart and delivered it to the
                                        other party, and each shall be deemed to be an
                                        original, notwithstanding that all parties are
                                        not signatory to the same counterpart. The
                                        Contract may be executed and delivered by either
                                        of the parties by transmitting to the other a
                                        copy of the Contract (executed by such
                                        delivering party ) by facsimile, telecopies or
                                        computer transmission (or similar means of
                                        electronic communication or transmission) and
                                        delivery in that manner by a party shall be
                                        binding upon such party and deemed to be an
                                        original.
                                    </li>
                                    <li>
                                        21.Except as otherwise provided in this
                                        Contract, this Contract may be amended solely by
                                        a written instrument signed by both of the
                                        parties, and which specifically states that it
                                        constitutes an amendment to this Contract.
                                    </li>
                                    <li>
                                        22.The failure of either party to enforce at any
                                        time any provision of this Contract shall in no
                                        way be construed as a waiver of such provision
                                        and shall not affect the ability of such party
                                        to thereafter enforce each and every such
                                        provision.
                                    </li>
                                </ul>
                                <br />
                                <p>EMPLOYEE CONTRACT</p>
                                <br />
                                <ul>
                                    <li>
                                        I understand that as a Wella
                                        Company&nbsp;employee, I have the benefit of
                                        purchasing available products from
                                        wella.professionalstore.com at an employee
                                        discount and am bound to the guidelines defined
                                        in the Professional Customer Contract and
                                        applicable terms and conditions. I agree not to
                                        re-sell any items purchased and understand that
                                        any such action can lead to disciplinary action
                                        up to and including termination of my
                                        employment.&nbsp; Wella Company reserves the
                                        right to change or suspend this benefit at any
                                        time including the level of discount &amp; which
                                        products are offered for purchase. If my
                                        employment at Wella Company ends, I will no
                                        longer have employee access to
                                        wella.professionalstore.com.
                                    </li>
                                </ul>
                                <br />
                                <p>STUDENT CONTRACT</p>
                                <br />
                                <ul>
                                    <li>
                                        I certify that I am currently enrolled as a
                                        cosmetology student and understand that I need
                                        to email a picture of my valid cosmetology
                                        student ID to the wella.professionalstore.com
                                        team to purchase professional salon products
                                        from wella.professionalstore.com at a student
                                        discount. After 12 months from today, if I am
                                        still enrolled in cosmetology school, I will
                                        need to resubmit a picture of my valid
                                        cosmetology student ID to maintain my student
                                        discount on wella.professionalstore.com. If no
                                        longer a student, I realize I must submit my
                                        cosmetology license information to access
                                        special graduation promotions and offers on
                                        wella.professionalstore.com. Student discounts
                                        on wella.professionalstore.com purchases are
                                        only available to cosmetology students.
                                    </li>
                                </ul>
                                <br />
                                <br />
                                <br />
                            </div>
                            <div className="professional-customer-contract-blur" />
                        </div>
                        <a
                            className="btn btn-default custom-cancel-btn btn-professional-customer-contract-expand js-professional-customer-contract-expand"
                            href="#"
                        >
                            EXPAND
                        </a>
                    </div>
                </div>
                <div
                    className="agreement-checkBoxes"
                    style={{ padding: "0 8px" }}
                >
                    <div className="row">
                        <div className="col-xs-12 col-md-10">
                            <div className="form-group">
                                <input
                                    id="contractConsent"
                                    name="contractConsent"
                                    className="js-contract-checkbox js-required"
                                    type="checkbox"
                                    defaultValue="true"
                                    required="required"
                                    data-required-error="This is a required field"
                                    data-validate="false"
                                />
                                <input
                                    type="hidden"
                                    name="_contractConsent"
                                    defaultValue="on"
                                />
                                <label className="" htmlFor="contractConsent">
                                    I have read and agree with the above contract for
                                    professional hairdressers.
                                    <span className="mandatory">*</span>
                                </label>
                                <span className="skip" />
                                <div className="help-block with-errors" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-10">
                            <div className="form-group">
                                <input
                                    id="professionalActivity"
                                    name="professionalActivity"
                                    className="js-einvoice-checkbox js-required"
                                    type="checkbox"
                                    defaultValue="true"
                                    required="required"
                                    data-required-error="This is a required field"
                                    data-validate="false"
                                />
                                <input
                                    type="hidden"
                                    name="_professionalActivity"
                                    defaultValue="on"
                                />
                                <label className="" htmlFor="professionalActivity">
                                    I confirm that, as a commercial customer in the
                                    beauty sector, I buy products for professional use
                                    and I guarantee that the information I have given
                                    under technical information is correct.
                                    <span className="mandatory">*</span>
                                </label>
                                <span className="skip" />
                                <div className="help-block with-errors" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-10">
                            <div className="form-group">
                                <input
                                    id="marketOptIn"
                                    name="marketOptIn"
                                    className="js-marketoptin-checkbox js-optional"
                                    type="checkbox"
                                    defaultValue="true"
                                    data-validate="false"
                                />
                                <input
                                    type="hidden"
                                    name="_marketOptIn"
                                    defaultValue="on"
                                />
                                <label className="" htmlFor="marketOptIn">
                                    I would like to receive marketing information from
                                    the{" "}
                                    <a
                                        href="https://www.wellacompany.com/our-brands"
                                        target="_blank"
                                    >
                                        Wella Brands
                                    </a>
                                    <br />
                                    We would like to keep you informed by email, text
                                    messages and other digital communications
                                    (including via targeted adverts online and via
                                    social media) about the latest offers, styling
                                    tips, trends and products from Wella Brands.
                                    <span className="optional">&nbsp;(optional)</span>
                                </label>
                                <span className="skip" />
                                <div className="help-block with-errors" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <div className="review-validate-message">
                            <div className="review-validate-message-icon">
                                <span className="review-validate-message-icon__inner">
                                    <i className="nc-icon-bookmark" />
                                </span>
                            </div>
                            <div className="review-validate-message-text">
                                We will review and validate your license information
                                before processing your order.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row account-step-buttons">
                    <span className="hidden-xs hidden-sm" style={{width: 'auto'}}>
                        <div className="col-xs-12 col-md-auto">
                            <a
                                onClick={this.props.backHandler}
                                className="btn btn-default custom-cancel-btn btn-block account-btn-prev"
                            >
                                BACK
                            </a>
                        </div>
                    </span>
                    <div className="col-xs-12 col-md-auto">
                        <a
                            href="#account-review-step"
                            className="btn btn-default custom-cancel-btn btn-block account-btn-review"
                        >
                            REVIEW MY DETAILS
                        </a>
                    </div>
                    <div className="col-xs-12 col-md-auto">
                        <a
                            onClick={this.props.nextHandler}
                            className="btn btn-primary btn-block account-btn-next "
                            data-validate-customer-url="/gigya-register/validateCustomer"
                        >
                            <span className="normal">NEXT</span>
                            <span className="review">REVIEW MY DETAILS</span>
                        </a>
                    </div>
                    <div className="col-xs-12 col-md-auto visible-xs visible-sm">
                        <a
                            onClick={this.props.backHandler}
                            className="btn btn-default custom-cancel-btn btn-block account-btn-prev"
                        >
                            BACK
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}