module.exports = Object.freeze({
  PROJECT_NAME: process.env.REACT_APP_PROJECT_NAME,

  IDENTITY_CONFIG: {
    authority: process.env.REACT_APP_AUTH_URL,
    client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
    redirect_uri: window.location.origin + "/signin/callback",
    response_type: "code",
    scope: "openid profile email",
    automaticSilentRenew: true,
    silent_redirect_uri: window.location.origin + "/silentrenew",
    loadUserInfo: true,
    post_logout_redirect_uri: window.location.origin + "/logout/callback",
  },
  METADATA_OIDC: {},

  API_URL: process.env.REACT_APP_API_URL
});