import React from 'react';
import { Component } from "react";
import { Link } from 'react-router-dom';
import './Register.scoped.scss';

export class RegisterPage extends Component {
    render() {
        return (
            <div className="container remove-container main-container">
                <div className="error-message"></div>
                <div className="account-section-register-panel">
                    <div className="loading-bar">
                        <div className="loader-5">
                            <span />
                        </div>
                    </div>
                    <div className="account-section-register-overview">
                        <div className="row account-section-register-overview-headline">
                            <div className="col-xs-12">
                                <h1>Register</h1>
                                <p>
                                    Already have an account?&nbsp;
                                    <Link to="/login">Sign in</Link>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 account-section-register-overview-box">
                                <div className="account-section-register-overview-box__inner">
                                    <h2>
                                        <span>I'M A</span> NEW PROFESSIONAL CUSTOMER
                                    </h2>
                                    <div className="content">
                                        I have a cosmetology license and I’m seeking education and
                                        products for professional use.
                                    </div>
                                    <div className="account-section-register-overview-box__action">
                                        <span className="button-timer">
                                            <Link to="/register/newcustomer" className="btn-signup">REGISTER</Link>
                                            <span className="info">
                                                <i className="nc-icon-timer-5" />
                                                <span className="text">5m</span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 account-section-register-overview-box">
                                <div className="account-section-register-overview-box__inner">
                                    <h2>
                                        <span>I'M A</span> NEW CONSUMER
                                    </h2>
                                    <div className="content">
                                        I am not a licensed professional and am purchasing products for
                                        personal use.
                                    </div>
                                    <div className="account-section-register-overview-box__action">
                                        <span className="button-timer">
                                            <Link to="/register/newConsumer" className="btn-signup">REGISTER</Link>
                                            <span className="info">
                                                <i className="nc-icon-timer-5" />
                                                <span className="text">5m</span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
