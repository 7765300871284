import React from "react";
import { Route, useHistory } from "react-router-dom";
import { UnauthorizedAccessPage } from "../pages/UnauthorizedAccessPage";
import { AuthConsumer } from "../providers/AuthProvider";

export const PrivateRoute = ({ component, ...rest }) => {
    const history = useHistory();

    const renderFn = (Component) => (props) => (
        <AuthConsumer>
            {({ isAuthenticated, signinRedirect, isAuthorized }) => {
                if (!!Component && isAuthenticated()) {
                    if (isAuthorized()) {
                        return <Component {...props} />;
                    } else {
                        return <UnauthorizedAccessPage />;
                    }
                } else {
                    history.push("/login");
                    //signinRedirect();
                    return <span>Signing in...</span>;
                }
            }}
        </AuthConsumer>
    );

    return <Route {...rest} render={renderFn(component)} />;
};