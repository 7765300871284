import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../providers/AuthProvider';
//import styles from './LoginPage.module.css';
import './Login.scoped.scss';

export async function loginUser(credentials) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "password");
    urlencoded.append("username", credentials.username);
    urlencoded.append("password", credentials.password);
    urlencoded.append("client_id", process.env.REACT_APP_IDENTITY_CLIENT_ID);
    urlencoded.append("scope", "openid profile email");

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    return fetch(process.env.REACT_APP_AUTH_URL + "/protocol/openid-connect/token", requestOptions)
        .then(response => {
            if (response.status !== 200) {
                throw new Error("Invalid Credentials");
            }
            return response.json();
        })
        .catch(error => alert(error))
}

export async function getUserInfo(token) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(process.env.REACT_APP_AUTH_URL + "/protocol/openid-connect/userinfo", requestOptions)
        .then(response => {
            if (response.status !== 200) {
                throw new Error("Invalid Credentials");
            }
            return response.json();
        })
        .catch(error => alert(error))
}

export default function LoginPage({ setToken }) {
    const history = useHistory();
    const authService = React.useContext(AuthContext);
    const isAuthenticated = authService.isAuthenticated();
    const [ isLoginFinished, setIsLoginFinished ] = useState(false);

    useEffect(() => {
        if (isAuthenticated) {
            history.push("/home");
            setIsLoginFinished(true);
        } else {
            authService.returnSigninSilent().then((userData) => {
                history.push("/home");
            }).finally(() => {
                setIsLoginFinished(true);
            });
/*            if (authService.signinSilent()) {
                authService.signinSilentCallback();
            }*/
        }
    });

    const [username, setUserName] = useState();
    const [password, setPassword] = useState();

    const handleSubmit = async e => {
        e.preventDefault();
        const token = await loginUser({
            username,
            password
        });
        if (token) {
            token.profile = await getUserInfo(token.access_token);
            setToken(token);
        }
    }

    const handleIdPLogin = idp => {
        authService.signinRedirectIdP(idp);
    };

    return (
        <div className='page-login pageType-ContentPage template-pages-LoginPageTemplate pageLabel-login smartedit-page-uid-login smartedit-page-uuid-eyJpdGVtSWQiOiJsb2dpbiIsImNhdGFsb2dJZCI6ImNvdHktdXNDb250ZW50Q2F0YWxvZyIsImNhdGFsb2dWZXJzaW9uIjoiT25saW5lIn0= smartedit-catalog-version-uuid-coty-usContentCatalog/Online  language-en'>
            { isLoginFinished && <div className="container remove-container main-container">
                <div className="error-message"></div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="yCmsContentSlot login-left-content-slot">
                            <div className="yCmsComponent login-left-content-component">
                                <div
                                    id="login-container"
                                    className="gigya-style-modern gigya-windows gigya-chrome"
                                >
                                    <h1
                                        className="gigya-screen-caption"
                                        id="login-container_content_caption"
                                        style={{ display: "none", width: 1500 }}
                                    >
                                        &nbsp;
                                    </h1>
                                    <div id="login-container_child" className="gigya-hidden" />
                                    <div
                                        id="login-container_content"
                                        className="gigya-screen-content gigya-locale-en"
                                        style={{ maxWidth: "none" }}
                                    >
                                        <div
                                            data-width="auto"
                                            className="gigya-screen v2 landscape wella-login-screen wella-login-screen-divider"
                                            data-css-class="wella-login-screen wella-login-screen-divider"
                                            data-screenset-element-id="gigya-login-screen"
                                            data-screenset-element-id-publish="true"
                                            data-screenset-roles="instance"
                                            gigya-default-class="gigya-screen v2 landscape wella-login-screen wella-login-screen-divider"
                                            gigya-default-data-caption="null"
                                            id="gigya-login-screen"
                                        >
                                            <form onSubmit={handleSubmit}
                                                className="gigya-login-form"
                                                id="gigya-login-form"
                                            >
                                                <div className="gigya-layout-row" />
                                                <div className="gigya-layout-row with-divider">
                                                    <div className="gigya-layout-cell responsive with-social-login">
                                                        <div className="">
                                                            <div
                                                                className="gigya-composite-control gigya-spacer"
                                                                data-units={1}
                                                                style={{ height: 1 }}
                                                            />
                                                            <h2
                                                                className="gigya-composite-control gigya-composite-control-header wella-login-label-returning-customer"
                                                                style={{ textAlign: "left" }}
                                                                data-translation-key="HEADER_157546076144829280_LABEL"
                                                                data-screenset-element-id="__gig_template_element_11_1662109029682"
                                                                data-screenset-element-id-publish="false"
                                                                data-screenset-roles="instance"
                                                            >
                                                                Returning Customer
                                                            </h2>
                                                            <label
                                                                className="gigya-composite-control gigya-composite-control-label wella-label"
                                                                data-binding="true"
                                                                style={{ display: "contents" }}
                                                                data-translation-key="LABEL_45979056654129336_LABEL"
                                                                data-screenset-element-id="__gig_template_element_12_1662109029682"
                                                                data-screenset-element-id-publish="false"
                                                                data-screenset-roles="template,instance,instance"
                                                            >
                                                                Welcome Back!
                                                            </label>
                                                        </div>
                                                        <label
                                                            className="gigya-composite-control gigya-composite-control-label wella-locked"
                                                            data-binding="true"
                                                            style={{ textAlign: "left", display: "none" }}
                                                            data-screenset-element-id="__gig_template_element_17_1662109029682"
                                                            data-screenset-element-id-publish="false"
                                                            data-screenset-roles="instance"
                                                        />
                                                        <div className="gigya-composite-control gigya-composite-control-textbox wella-input-text">
                                                            <label
                                                                className="gigya-label"
                                                                data-binding="true"
                                                                data-screenset-element-id="__gig_template_element_18_1662109029682"
                                                                data-screenset-element-id-publish="false"
                                                                data-screenset-roles="instance"
                                                                htmlFor="gigya-loginID-12388169254379640"
                                                            >
                                                                <span
                                                                    className="gigya-label-text"
                                                                    data-translation-key="LOGINID_93927439260069040_LABEL"
                                                                    data-screenset-element-id="__gig_template_element_13_1662109029682"
                                                                    data-screenset-element-id-publish="false"
                                                                    data-screenset-roles="instance,template"
                                                                >
                                                                    <p
                                                                        style={{
                                                                            fontSize: "14px !important",
                                                                            fontFamily:
                                                                                '"aspiraRegular", "aspiraRegular", Helvetica, Arial, sans-serif !important'
                                                                        }}
                                                                    >
                                                                        Email Address
                                                                    </p>
                                                                </span>
                                                                <label
                                                                    className="gigya-required-display gigya-reset"
                                                                    data-bound-to="loginID"
                                                                    style={{}}
                                                                    data-screenset-element-id="__gig_template_element_9_1662109029682"
                                                                    data-screenset-element-id-publish="false"
                                                                    data-screenset-roles="instance"
                                                                    aria-hidden="true"
                                                                >
                                                                    *
                                                                </label>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="gigya-input-text"
                                                                name="username"
                                                                tabIndex={0}
                                                                autoComplete="off"
                                                                onChange={e => setUserName(e.target.value)}
                                                                id="gigya-loginID-12388169254379640"
                                                            />
                                                            <span
                                                                className="gigya-error-msg"
                                                                data-bound-to="loginID"
                                                                data-screenset-element-id="__gig_template_element_2_1662109029680"
                                                                data-screenset-element-id-publish="false"
                                                                data-screenset-roles="instance"
                                                                aria-atomic="true"
                                                            />
                                                        </div>
                                                        <div className="gigya-composite-control gigya-composite-control-password wella-input-text">
                                                            <label
                                                                className="gigya-label"
                                                                data-binding="true"
                                                                data-screenset-element-id="__gig_template_element_19_1662109029682"
                                                                data-screenset-element-id-publish="false"
                                                                data-screenset-roles="instance"
                                                                htmlFor="gigya-password-135293725948634270"
                                                            >
                                                                <span
                                                                    className="gigya-label-text"
                                                                    data-translation-key="PASSWORD_20061494271860624_LABEL"
                                                                    data-screenset-element-id="__gig_template_element_14_1662109029682"
                                                                    data-screenset-element-id-publish="false"
                                                                    data-screenset-roles="instance,template"
                                                                >
                                                                    <p
                                                                        style={{
                                                                            fontSize: "14px !important",
                                                                            fontFamily:
                                                                                '"aspiraRegular", "aspiraRegular", Helvetica, Arial, sans-serif !important'
                                                                        }}
                                                                    >
                                                                        Password
                                                                    </p>
                                                                </span>
                                                                <label
                                                                    className="gigya-required-display gigya-reset"
                                                                    data-bound-to="password"
                                                                    style={{}}
                                                                    data-screenset-element-id="__gig_template_element_10_1662109029682"
                                                                    data-screenset-element-id-publish="false"
                                                                    data-screenset-roles="instance"
                                                                    aria-hidden="true"
                                                                >
                                                                    *
                                                                </label>
                                                            </label>
                                                            <input
                                                                type="password"
                                                                name="password"
                                                                className="gigya-input-password"
                                                                tabIndex={0}
                                                                autoComplete="off"
                                                                onChange={e => setPassword(e.target.value)}
                                                                id="gigya-password-135293725948634270"
                                                            />
                                                            <span
                                                                className="gigya-error-msg"
                                                                data-bound-to="password"
                                                                data-screenset-element-id="__gig_template_element_3_1662109029680"
                                                                data-screenset-element-id-publish="false"
                                                                data-screenset-roles="instance"
                                                                aria-atomic="true"
                                                            />
                                                            <span
                                                                className="passwordEyeSpan"
                                                                id="span_gigya-password-135293725948634270"
                                                            >
                                                                <span className="pwIcon eyeIcon">
                                                                    <svg
                                                                        width={20}
                                                                        height={20}
                                                                        viewBox="0 0 20 20"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M10.0004 4.00462C12.2095 4.00462 14.0004 5.79549 14.0004 8.00462C14.0004 10.2138 12.2095 12.0046 10.0004 12.0046C7.79122 12.0046 6.00036 10.2138 6.00036 8.00462C6.00036 5.79549 7.79122 4.00462 10.0004 4.00462ZM10.0004 5.50462C8.61965 5.50462 7.50036 6.62391 7.50036 8.00462C7.50036 9.38534 8.61965 10.5046 10.0004 10.5046C11.3811 10.5046 12.5004 9.38534 12.5004 8.00462C12.5004 6.62391 11.3811 5.50462 10.0004 5.50462ZM10.0004 0.5C14.6139 0.5 18.5965 3.65001 19.7015 8.06439C19.8021 8.4662 19.5579 8.87348 19.1561 8.97406C18.7542 9.07465 18.347 8.83045 18.2464 8.42863C17.3075 4.67796 13.9217 2 10.0004 2C6.07729 2 2.69046 4.68026 1.75322 8.43315C1.65286 8.83502 1.24572 9.07944 0.843845 8.97908C0.441975 8.87872 0.197554 8.47158 0.297916 8.06971C1.40101 3.65272 5.38484 0.5 10.0004 0.5Z"
                                                                            fill="#8A8A8A"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                                <span className="pwIcon eyeIconClosed">
                                                                    <svg
                                                                        width={20}
                                                                        height={20}
                                                                        viewBox="0 0 20 20"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M0.219669 0.21967C-0.0465965 0.485936 -0.0708046 0.9026 0.147051 1.19621L0.219669 1.28033L4.25424 5.3149C2.33225 6.66437 0.895775 8.67992 0.298883 11.0644C0.198299 11.4662 0.442495 11.8735 0.844311 11.9741C1.24613 12.0746 1.6534 11.8305 1.75399 11.4286C2.28346 9.31347 3.59112 7.53947 5.33416 6.39452L7.14379 8.20425C6.43628 8.92578 6 9.91426 6 11.0046C6 13.2138 7.79086 15.0046 10 15.0046C11.0904 15.0046 12.0788 14.5683 12.8004 13.8608L18.7197 19.7803C19.0126 20.0732 19.4874 20.0732 19.7803 19.7803C20.0466 19.5141 20.0708 19.0974 19.8529 18.8038L19.7803 18.7197L13.6668 12.6055L13.668 12.604L12.4679 11.4061L9.59797 8.53678L9.6 8.536L6.71877 5.65782L6.72 5.656L5.58672 4.52549L1.28033 0.21967C0.987438 -0.0732233 0.512562 -0.0732233 0.219669 0.21967ZM8.20407 9.26547L11.7392 12.8006C11.2892 13.2364 10.6759 13.5046 10 13.5046C8.61929 13.5046 7.5 12.3853 7.5 11.0046C7.5 10.3287 7.76824 9.71544 8.20407 9.26547ZM10 3.5C8.99974 3.5 8.02914 3.64807 7.11109 3.925L8.34807 5.16119C8.88386 5.05532 9.4364 5 10 5C13.9231 5 17.3099 7.68026 18.2471 11.4332C18.3475 11.835 18.7546 12.0794 19.1565 11.9791C19.5584 11.8787 19.8028 11.4716 19.7024 11.0697C18.5994 6.65272 14.6155 3.5 10 3.5ZM10.1947 7.00928L13.996 10.81C13.8942 8.75308 12.2472 7.10764 10.1947 7.00928Z"
                                                                            fill="#8A8A8A"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <div
                                                            className="gigya-composite-control gigya-spacer"
                                                            data-units={1}
                                                            style={{ height: 1 }}
                                                        />
                                                        <div
                                                            className="gigya-composite-control gigya-spacer"
                                                            data-units={1}
                                                            style={{ height: 1 }}
                                                        />
                                                        <div className="gigya-composite-control gigya-composite-control-submit wella-submit wella-btn-sign-in">
                                                            <input
                                                                type="submit"
                                                                className="gigya-input-submit"
                                                                tabIndex={0}
                                                                data-gigya-type="submit"
                                                                data-screenset-element-id="__gig_template_element_6_1662109029681"
                                                                data-screenset-element-id-publish="false"
                                                                data-screenset-roles="instance"
                                                                gigya-default-value="null"
                                                                defaultValue="SIGN IN"
                                                            />
                                                        </div>
                                                        <hr style={{ margin: "0 0 1rem", color: "inherit", border: 0,  borderTop: "1px solid", opacity: ".25"}}></hr>
                                                        <div>
                                                            <span>Login with: </span>
                                                            <a style={{ margin: "0 10px", cursor: "pointer" }} onClick={() => { handleIdPLogin("google") }}><img title='Google' src="/google.png" /></a>
                                                            <a style={{ margin: "0 10px", cursor: "pointer" }} onClick={() => { handleIdPLogin("instagram") }}><img title='Instagram' src="/instagram.png" /></a>
                                                            <div style={{ float: "right", borderLeft: "1px solid #ddd" }} title='Login with your Wella Employee Credentials'>
                                                                <a style={{ margin: "0 10px", textDecoration: "none", cursor: "pointer" }} onClick={() => { handleIdPLogin("oidc") }}><img style={{ verticalAlign: "bottom"}}  src="/microsoft.png" /> <span>Wella Employees</span></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="gigya-layout-cell responsive with-divider"></div>
                                                    <div className="gigya-layout-cell responsive with-site-login">
                                                        <div className="gigya-layout-row">
                                                            <div className="gigya-layout-cell">
                                                                <label
                                                                    className="gigya-composite-control gigya-composite-control-label wellaRegistration-label"
                                                                    data-binding="true"
                                                                    style={{ textAlign: "left" }}
                                                                    data-screenset-element-id="__gig_template_element_20_1662109029682"
                                                                    data-screenset-element-id-publish="false"
                                                                    data-screenset-roles="instance"
                                                                />
                                                            </div>
                                                            <div className="gigya-layout-cell"></div>
                                                        </div>
                                                        <h2
                                                            className="gigya-composite-control gigya-composite-control-header wella-login-label-returning-customer"
                                                            style={{ textAlign: "left" }}
                                                            data-translation-key="HEADER_120574982431723800_LABEL"
                                                            data-screenset-element-id="__gig_template_element_16_1662109029682"
                                                            data-screenset-element-id-publish="false"
                                                            data-screenset-roles="instance"
                                                        >
                                                            New customer
                                                        </h2>
                                                        <div className="gigya-composite-control gigya-composite-control-submit wella-submit wella-button-create-account">
                                                            <input
                                                                type="button"
                                                                className="gigya-input-submit"
                                                                data-gigya-type="button"
                                                                data-screenset-element-id="__gig_template_element_7_1662109029681"
                                                                data-screenset-element-id-publish="false"
                                                                data-screenset-roles="instance"
                                                                gigya-default-value="null"
                                                                defaultValue="Create Account"
                                                                onClick={() => { history.push('/register') }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="gigya-clear" />
                                                </div>
                                                <div className="gigya-layout-row">
                                                    <div className="gigya-layout-cell responsive under-site-login"></div>
                                                </div>
                                                <div className="gigya-clear" />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div id="dialog" title="Basic dialog"></div>
                            </div>
                        </div>
                    </div>
                    <div className="login-section"></div>
                </div>
            </div> || <span>Checking Active SSO Sessions...</span> }
        </div>
    )
}

LoginPage.propTypes = {
    setToken: PropTypes.func.isRequired
}
