import React from 'react';
import './Footer.scoped.scss';

function Footer() {
    return (
        <footer>
            <div className="container">
                <div className="content">
                    <div>
                        <div className="footer__top">
                            <div className="row">
                                <div className="col-xs-12 col-sm-4 text-center">
                                    <div className="wella-separator">
                                        <div className="header-seperator">WORLD OF WELLA</div>
                                        <ul className="contact">
                                            <li className="yCmsComponent"><a href="/faq">FAQ</a></li>
                                            <li className="yCmsComponent"><a href="/ContactUs">CONTACT</a></li>
                                            <li className="yCmsComponent"><a href="https://www.wellacompany.com/join-us" target="_blank" rel="noopener noreferrer">JOIN WELLA</a></li>
                                            <li className="yCmsComponent"><a href="/globalelite">WELLA REWARDS LOYALTY
                                                PROGRAM</a></li>
                                            <li className="yCmsComponent"><a href="https://www.wella.com/professional/en-US/subscribe" target="_blank" rel="noopener noreferrer">SUBSCRIBE</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-4 text-center">
                                </div>
                                <div className="col-xs-12 col-sm-4 text-center">
                                </div>
                            </div>
                        </div>
                        <div className="footer__bottom">
                            <div className="row">
                                <div className="countrySelector col-xs-12 col-sm-3 text-center">
                                    <div className="footer__dropdown">
                                        <div className="form-group">
                                            <div className="btn-group dropup lang-selector"><button aria-expanded="false" aria-haspopup="true" className="form-control dropdown-toggle" data-toggle="dropdown" type="button"><img alt="" className="lang-selector-planet" src="https://us.wella.professionalstore.com/_ui/responsive/theme-cotyThemeAT/images/planet.svg" /> Change
                                                country</button>
                                                <ul className="dropdown-menu">
                                                    <li><a href="https://us.wella.professionalstore.com">US</a></li>
                                                    <li><a href="https://ca.wella.professionalstore.com">Canada</a></li>
                                                    <li><a href="https://be.wella.professionalstore.com/?lang=nl_BE">Belgium</a>
                                                    </li>
                                                    <li><a href="https://be.wella.professionalstore.com/?lang=fr_BE">Belgique</a>
                                                    </li>
                                                    <li><a href="https://dk.wella.professionalstore.com">Denmark</a></li>
                                                    <li><a href="https://de.wella.professionalstore.com">Deutschland</a>
                                                    </li>
                                                    <li><a href="https://es.wella.professionalstore.com">España</a></li>
                                                    <li><a href="https://fi.wella.professionalstore.com">Finland</a></li>
                                                    <li><a href="https://fr.wella.professionalstore.com">France</a></li>
                                                    <li><a href="https://gr.wella.professionalstore.com">Ελλάδα</a></li>
                                                    <li><a href="https://ie.wella.professionalstore.com">Ireland</a></li>
                                                    <li><a href="https://it.wella.professionalstore.com">Italia</a></li>
                                                    <li><a href="https://be.wella.professionalstore.com/?lang=fr_BE">Luxembourg</a>
                                                    </li>
                                                    <li><a href="https://nl.wella.professionalstore.com">Nederland</a></li>
                                                    <li><a href="https://no.wella.professionalstore.com">Norge</a></li>
                                                    <li><a href="https://at.wella.professionalstore.com">Österreich</a></li>
                                                    <li><a href="https://pt.wella.professionalstore.com">Portugal</a></li>
                                                    <li><a href="https://uk.wella.professionalstore.com">United Kingdom</a>
                                                    </li>
                                                    <li><a href="https://ch.wella.professionalstore.com/?lang=de_CH">Schweiz</a>
                                                    </li>
                                                    <li><a href="https://ch.wella.professionalstore.com/?lang=fr_CH">Suisse</a>
                                                    </li>
                                                    <li><a href="https://se.wella.professionalstore.com">Sverige</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 text-center">
                                    <ul className="terms">
                                        <li className="yCmsComponent first"><a href="http://www.wella.com/professional/en-US/sitemap">sitemap</a></li>
                                        <li className="yCmsComponent"><a href="https://www.wellacompany.com/terms-and-conditions" target="_blank" rel="noopener noreferrer">terms &amp; conditions</a></li>
                                        <li className="yCmsComponent"><a href="https://www.wellacompany.com/cookie-policy" target="_blank" rel="noopener noreferrer">about cookies</a></li>
                                        <li className="yCmsComponent"><a className="ot-sdk-show-settings" href="#">Cookies
                                            Settings</a></li>
                                        <li className="yCmsComponent"><a href="https://www.wellacompany.com/en-us/privacy-policy" target="_blank" rel="noopener noreferrer">privacy notice</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 text-center copyright">Copyright 2022, Wella Operations US
                                    LLC, all trademarks registered. All rights reserved.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
