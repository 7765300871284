import React, { Component } from "react";
import '../ProfessionalRegister/ProfessionalRegister.scoped.scss';

export default class Step5AccountShippingAddressForm extends Component {
    render() {
        return (
            <div
                id="account-shipping-address-step"
                className="account-step"
            >
                <div className="row account-step-back">
                    <div className="col-xs-12">
                        <a onClick={this.props.backHandler}>
                            <span className="glyphicon glyphicon-chevron-left" />
                            BACK
                        </a>
                    </div>
                </div>
                <h2>Shipping Address</h2>
                <div className="step-progress">
                    <div className="step-progress-label">
                        <span className="text">STEP</span>
                        <span className="number">
                            <span className="active">{ this.props.registerFormType == "1" ? "5" : "3"}</span> / { this.props.registerFormType == "1" ? "7" : "5"}
                        </span>
                    </div>
                    <ul className="step-progress-bar">
                        <li className="done" />
                        { this.props.registerFormType == "1" ? <li className="done" /> : ""}
                        { this.props.registerFormType == "1" ? <li className="done" /> : ""}
                        <li className="done" />
                        <li className="active" />
                        <li className="" />
                        <li className="" />
                    </ul>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <div className="form-group">
                            <div className="form-check">
                                <input
                                    id="billingAsShippingcheckbox-true"
                                    name="billingAsShippingcheckbox"
                                    className="form-control js-billing-address-radio"
                                    type="radio"
                                    defaultValue={1}
                                    defaultChecked="checked"
                                    data-validate="false"
                                />
                                <label
                                    className="form-control-label"
                                    htmlFor="billingAsShippingcheckbox-true"
                                >
                                    Use the same address as for billing
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    id="billingAsShippingcheckbox-false"
                                    name="billingAsShippingcheckbox"
                                    className="form-control js-billing-address-radio"
                                    type="radio"
                                    defaultValue={0}
                                    data-validate="false"
                                />
                                <label
                                    className="form-control-label"
                                    htmlFor="billingAsShippingcheckbox-false"
                                >
                                    Use different address for shipping
                                </label>
                            </div>
                            <div className="help-block with-errors" />
                        </div>
                    </div>
                </div>
                <div className="row account-step-buttons">
                    <span className="hidden-xs hidden-sm" style={{width: 'auto'}}>
                        <div className="col-xs-12 col-md-auto">
                            <a
                                onClick={this.props.backHandler}
                                className="btn btn-default custom-cancel-btn btn-block account-btn-prev"
                            >
                                BACK
                            </a>
                        </div>
                    </span>
                    <div className="col-xs-12 col-md-auto">
                        <a
                            onClick={this.props.nextHandler}
                            className="btn btn-primary btn-block account-btn-next "
                            data-validate-customer-url="/gigya-register/validateCustomer"
                        >
                            <span className="normal">NEXT</span>
                            <span className="review">REVIEW MY DETAILS</span>
                        </a>
                    </div>
                    <div className="col-xs-12 col-md-auto visible-xs visible-sm">
                        <a
                            onClick={this.props.backHandler}
                            className="btn btn-default custom-cancel-btn btn-block account-btn-prev"
                        >
                            BACK
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}