import React, { Component } from "react";
import { Link, useHistory } from "react-router-dom";
import '../ProfessionalRegister/ProfessionalRegister.scoped.scss';

export default class Step1AccountDetails extends Component {
    constructor() {
        super();
        this.state = {
            name: "React",
            isEmailValid: true,
            isFirstNameValid: true,
            isLastNameValid: true
        }
    }

    render() {
        let formData = this.props.getFormData();
        return (
            <div
                id="account-detail-step"
                className="account-step"
            >
                <div className="row account-step-back">
                    <div className="col-xs-12">
                        <Link to='/register' className="js-is-url">
                            <span className="glyphicon glyphicon-chevron-left" />
                            BACK
                        </Link>
                    </div>
                </div>
                <h2>Account Details</h2>
                <div className="step-progress">
                    <div className="step-progress-label">
                        <span className="text">STEP</span>
                        <span className="number">
                            <span className="active">1</span> / { this.props.registerFormType == "1" ? "7" : "5"}
                        </span>
                    </div>
                    <ul className="step-progress-bar">
                        <li className="active" />
                        { this.props.registerFormType == "1" ? <li className="" /> : ""}
                        { this.props.registerFormType == "1" ? <li className="" /> : ""}
                        <li className="" />
                        <li className="" />
                        <li className="" />
                        <li className="" />
                    </ul>
                </div>
                <div className="row required-info">
                    <div className="col-xs-12">
                        <span>*</span> Required Fields
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-md-10 form-group-phone"></div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-md-10">
                        <div className={this.state.isEmailValid ? "form-group" : "form-group has-error has-danger"}>
                            <label className="control-label " htmlFor="email">
                                E-mail address<span className="mandatory">*</span>
                            </label>
                            <input
                                id="email"
                                name="email"
                                className="js-email-input form-control js-required"
                                type="text"
                                required="required"
                                defaultValue={formData['email']}
                                onChange={(e) => this.props.setFormData("email", e.target.value)}
                            />
                            { !this.state.isEmailValid ? <div className="help-block with-errors"><ul className="list-unstyled"><li>This is a required field with valid email address</li></ul></div> : ""}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <div className={this.state.isFirstNameValid ? "form-group" : "form-group has-error has-danger"}>
                            <label className="control-label " htmlFor="firstName">
                                First Name<span className="mandatory">*</span>
                            </label>
                            <input
                                id="firstName"
                                name="firstName"
                                className="js-firstName-input form-control js-required"
                                type="text"
                                required="required"
                                defaultValue={formData['firstName']}
                                onChange={(e) => this.props.setFormData("firstName", e.target.value)}
                                />
                            { !this.state.isFirstNameValid ? <div className="help-block with-errors"><ul className="list-unstyled"><li>This is a required field</li></ul></div> : ""}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <div className={this.state.isLastNameValid ? "form-group" : "form-group has-error has-danger"}>
                            <label className="control-label " htmlFor="lastName">
                                Last Name<span className="mandatory">*</span>
                            </label>
                            <input
                                id="lastName"
                                name="lastName"
                                className="js-lastName-input form-control js-required"
                                type="text"
                                required="required"
                                defaultValue={formData['lastName']}
                                onChange={(e) => this.props.setFormData("lastName", e.target.value)}
                                />
                            { !this.state.isLastNameValid ? <div className="help-block with-errors"><ul className="list-unstyled"><li>This is a required field</li></ul></div> : ""}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-md-10">
                        <div className="trust-message">
                            <div className="trust-message-icon">
                                <span className="trust-message-icon__inner">
                                    <i className="nc-icon-award" />
                                </span>
                            </div>
                            <div className="trust-message-text">
                                Wella Company is committed to maintaining your trust
                                by protecting the personal information we receive
                                from you.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-md-10">
                        <div className="form-group">
                            <input
                                id="termsnCondition"
                                name="termsnCondition"
                                className="js-terms-privacy js-required"
                                type="checkbox"
                                defaultValue="true"
                                required="required"
                                data-required-error="This is a required field"
                                data-validate="true"
                            />
                            <input
                                type="hidden"
                                name="_termsnCondition"
                                defaultValue="on"
                            />
                            <label className="" htmlFor="termsnCondition">
                                I agree with the{" "}
                                <a
                                    href="https://www.wellacompany.com/terms-and-conditions"
                                    target="_blank"
                                >
                                    General terms and conditions
                                </a>{" "}
                                and{" "}
                                <a
                                    href="https://www.wellacompany.com/en-us/privacy-policy"
                                    target="_blank"
                                >
                                    Privacy Policy
                                </a>
                                .<span className="mandatory">*</span>
                            </label>
                            <span className="skip" />
                            <div className="help-block with-errors" />
                        </div>
                    </div>
                </div>
                <div className="row account-step-buttons">
                    <span className="hidden-xs hidden-sm" style={{width: 'auto'}}>
                        <div className="col-xs-12 col-md-auto">
                            <Link
                                to="/register"
                                className="btn btn-default custom-cancel-btn btn-block account-btn-prev js-is-url"
                            >
                                BACK
                            </Link>
                        </div>
                    </span>
                    <div className="col-xs-12 col-md-auto">
                        <a
                            onClick={() => {if (this.validateForm()) {this.props.nextHandler()}}}
                            className="btn btn-primary btn-block account-btn-next "
                            data-validate-customer-url="/gigya-register/validateCustomer"
                        >
                            <span className="normal">NEXT</span>
                            <span className="review">REVIEW MY DETAILS</span>
                        </a>
                    </div>
                    <div className="col-xs-12 col-md-auto visible-xs visible-sm">
                        <Link
                            to="/register"
                            className="btn btn-default custom-cancel-btn btn-block account-btn-prev js-is-url"
                        >
                            BACK
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    validateForm() {
        let formData = this.props.getFormData();
        let hasError = false;
        if (formData['email'] === "" || !formData['email'].match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            this.setState({isEmailValid: false});
            hasError = true;
        } else {
            this.setState({isEmailValid: true});
        }
        if (formData['firstName'] === "") {
            this.setState({isFirstNameValid: false});
            hasError = true;
        } else {
            this.setState({isFirstNameValid: true});
        }
        if (formData['lastName'] === "") {
            this.setState({isLastNameValid: false});
            hasError = true;
        } else {
            this.setState({isLastNameValid: true});
        }

        if (hasError) return false;
        return true;
    }
}
