import React from "react";
import { AuthContext } from "../providers/AuthProvider";

export default function Wellaverse({ setToken }) {
    const authService = React.useContext(AuthContext);
    authService.signinRedirect();

    return (
        <div>Please wait...</div>
    );
}