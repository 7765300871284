import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../providers/AuthProvider';

export default function DoSilentLogin() {
    const history = useHistory();
    const authService = React.useContext(AuthContext);
    const isAuthenticated = authService.isAuthenticated();

    useEffect(() => {
        console.log(isAuthenticated);
        if (isAuthenticated) {
            history.push("/home");
        } else {
            /*authService.returnSigninSilent().then((userData) => {
                history.push("/home");
            })
            .catch((err) => {*/
                window.location.href="login";
//            });
        }
    });

    return (
        <span></span>
    );
}