import React from "react";

function WelcomePage() {
    return (
        <div className="container remove-container main-container">
            <div className="error-message"></div>
            <div className="coty-page">
                <div className="row">
                    <div className="yCmsContentSlot account-section-content"></div>
                </div>
                <div className="row">
                    <div className="yCmsContentSlot col-md-12">
                        <div className="content">
                            <div className="thankyou thankyou-header">
                                <div className="flex">
                                    <div>
                                    </div>
                                    <div className="center">
                                        <div className="wellanew">
                                            <h5>Enjoy 10% off your first order using code WELLAFAMILY</h5>
                                            <a className="btn-filled" href="/">
                                                Start Shopping
                                            </a>
                                            <p className="note">
                                                <br />
                                                <br />
                                                *New customers only. Promo valid on regularly priced items
                                                only.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="homepage-content-components">
                                &nbsp;
                                <div className="section-title">Shop by brand</div>
                            </div>
                            <div className="padding" />
                        </div>
                        <div className="container">
                            <hr className="product-carousel-hr" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="yCmsContentSlot col-xs-12 col-md-6"></div>
                    <div className="yCmsContentSlot col-xs-12 col-md-6"></div>
                </div>
                <div className="row">
                    <div className="yCmsContentSlot col-md-12"></div>
                </div>
                <div className="row">
                    <div className="yCmsContentSlot col-md-12"></div>
                </div>
                <div className="row">
                    <div className="yCmsContentSlot col-md-12"></div>
                </div>
                <div className="row">
                    <div className="yCmsContentSlot col-md-12"></div>
                </div>
                <hr />
                <div className="row">
                    <div className="yCmsContentSlot col-md-12"></div>
                </div>
                <div className="row">
                    <div className="yCmsContentSlot col-md-12"></div>
                </div>
                <div className="row">
                    <div className="yCmsContentSlot col-md-12"></div>
                </div>
                <div className="row">
                    <div className="yCmsContentSlot col-md-12"></div>
                </div>
            </div>
        </div>

    );
}

export default WelcomePage;
