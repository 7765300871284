import React from 'react';
import { Alert, Box, Container } from "@mui/material";
import { Component } from "react";

export class UnauthorizedAccessPage extends Component {
    render () {
        return (
          <Container maxWidth="sm">
            <Box>
              <Alert variant="filled" severity="error" sx={{fontSize: 16}}>
              You are not authorized to access this application. Please contact the Support team for access.
              </Alert>
            </Box>
          </Container>
        );
    }
}
