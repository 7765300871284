import React, { Component } from "react";
import Step1AccountDetails from "../RegisterFormParts/Step1AccountDetails";
import Step2AccountProfessionalOccupationDetailsForm from "../RegisterFormParts/Step2AccountProfessionalOccupationDetailsForm";
import Step3AccountProfessionalDetailForm from "../RegisterFormParts/Step3AccountProfessionalDetailForm";
import Step4AccountBillingAddressForm from "../RegisterFormParts/Step4AccountBillingAddressForm";
import Step5AccountShippingAddressForm from "../RegisterFormParts/Step5AccountShippingAddressForm";
import Step6AccountProfessionalAgreementForm from "../RegisterFormParts/Step6AccountProfessionalAgreementForm";
import Step7AccountPasswordForm from "../RegisterFormParts/Step7AccountPasswordForm";
import '../ProfessionalRegister/ProfessionalRegister.scoped.scss';
import singletonRestServiceInstance from "../../../services/RestService";
import { getUserInfo, loginUser } from "../../LoginPage";

export default class ProfessionalRegister extends Component {
    formData = {
        "firstName": "",
        "lastName": "",
        "username": "",
        "email": "",
        "enabled": "true",
        "emailVerified": "true",
        "groups": [
        ],
        "attributes": {
            "professionalType": "Salon Owner ordering for multiple stylists",
            "businessName": "",
            "professionalLicenceNumber": "",
            "salesRepresentativeName": "",
            "billingAddress.firstName": "",
            "billingAddress.lastName": "",
            "billingAddress.streetAddress": "",
            "billingAddress.city": "",
            "billingAddress.state": "",
            "billingAddress.postalCode": "",
            "billingAddress.telephone": "",
            "billingAddress.mobileNumber": "",
            "isShippingAddressDifferent": "false",
            "shippingAddress.firstName": "",
            "shippingAddress.lastName": "",
            "shippingAddress.streetAddress": "",
            "shippingAddress.city": "",
            "shippingAddress.state": "",
            "shippingAddress.postalCode": "",
            "shippingAddress.telephone": "",
            "shippingAddress.mobileNumber": ""
        },
        "credentials": [
            {
                "type": "password",
                "value": "",
                "temporary": false
            }
        ]
    };

    constructor(props) {
        super(props);
        this.props.redirectToHome();
        this.state = {
            name: "React",
            showStep1AccountDetails: true,
            showStep2AccountProfessionalOccupationDetailsForm: false,
            showStep3AccountProfessionalDetailForm: false,
            showStep4AccountBillingAddressForm: false,
            showStep5AccountShippingAddressForm: false,
            showStep6AccountProfessionalAgreementForm: false,
            showStep7AccountPasswordForm: false,
            errorMessage: ""
        };
        this.showComponent = this.showComponent.bind(this);
        this.setFormData = this.setFormData.bind(this);
    }

    setFormData(key, value) {
        if (["firstName", "lastName", "email"].includes(key)) {
            if (key == "email") {
                this.formData["username"] = value;
            }
            this.formData[key] = value;
        } else if (key == "password") {
            this.formData['credentials'][0]['value'] = value;
        } else {
            this.formData['attributes'][key] = value;
        }
        console.log(this.formData);
        return;
    }

    getFormData() {
        return this.formData;
    }

    showComponent(name) {
        console.log(name);
        this.setState({ showStep1AccountDetails: false });
        this.setState({ showStep2AccountProfessionalOccupationDetailsForm: false });
        this.setState({ showStep3AccountProfessionalDetailForm: false });
        this.setState({ showStep4AccountBillingAddressForm: false });
        this.setState({ showStep5AccountShippingAddressForm: false });
        this.setState({ showStep6AccountProfessionalAgreementForm: false });
        this.setState({ showStep7AccountPasswordForm: false });
        switch (name) {
            case "showStep1AccountDetails":
                this.setState({ showStep1AccountDetails: true });
                break;
            case "showStep2AccountProfessionalOccupationDetailsForm":
                this.setState({ showStep2AccountProfessionalOccupationDetailsForm: true });
                break;
            case "showStep3AccountProfessionalDetailForm":
                this.setState({ showStep3AccountProfessionalDetailForm: true });
                break;
            case "showStep4AccountBillingAddressForm":
                this.setState({ showStep4AccountBillingAddressForm: true });
                break;
            case "showStep5AccountShippingAddressForm":
                this.setState({ showStep5AccountShippingAddressForm: true });
                break;
            case "showStep6AccountProfessionalAgreementForm":
                this.setState({ showStep6AccountProfessionalAgreementForm: true });
                break;
            case "showStep7AccountPasswordForm":
                this.setState({ showStep7AccountPasswordForm: true });
                break;
        }
    }

    async afterRegistration() {
        let username = this.formData['email'];
        let password = this.formData['credentials'][0]['value'];
        const token = await loginUser({
            username,
            password
        });
        if (token) {
            token.profile = await getUserInfo(token.access_token);
            this.props.setToken(token);
            this.props.redirectToHome();
        }
    }

    onSubmit() {
        this.formData['attributes']['shippingAddress.firstName'] = this.formData['attributes']['billingAddress.firstName'];
        this.formData['attributes']['shippingAddress.lastName'] = this.formData['attributes']['billingAddress.lastName'];
        this.formData['attributes']['shippingAddress.streetAddress'] = this.formData['attributes']['billingAddress.streetAddress'];
        this.formData['attributes']['shippingAddress.city'] = this.formData['attributes']['billingAddress.city'];
        this.formData['attributes']['shippingAddress.state'] = this.formData['attributes']['billingAddress.state'];
        this.formData['attributes']['shippingAddress.postalCode'] = this.formData['attributes']['billingAddress.postalCode'];
        this.formData['attributes']['shippingAddress.telephone'] = this.formData['attributes']['billingAddress.telephone'];
        this.formData['attributes']['shippingAddress.mobileNumber'] = this.formData['attributes']['billingAddress.mobileNumber'];
        
        this.setState({ isRequestUnderProcess: true, errorMessage: "" });
        // Register User
        singletonRestServiceInstance.registerUser(this.formData).then((response) => {
            this.afterRegistration();
        }).catch((error) => {
            this.state.errorMessage = error.response['data']['errorMessage'];
        }).finally(() => {
            this.setState({ isRequestUnderProcess: false });
        });
    };

    render() {
        const { showStep1AccountDetails, showStep2AccountProfessionalOccupationDetailsForm, showStep3AccountProfessionalDetailForm, showStep4AccountBillingAddressForm, showStep5AccountShippingAddressForm, showStep6AccountProfessionalAgreementForm, showStep7AccountPasswordForm, errorMessage } = this.state;
        return (
            <div className="container remove-container main-container">
                <div className="error-message"></div>
                <div className="account-section">
                    <div className="account-section-content">
                        <div className="account-section-register-panel">
                            <div className="loading-bar">
                                <div className="loader-5">
                                    <span />
                                </div>
                            </div>
                            <div className="account-section-register-flow">
                                <div
                                    className="account-register account-register-pro__new"
                                    data-cdc-light-reg-url="/gigya-register-lite/nextstep"
                                    data-email-exists-url="/gigya-register/emailExists"
                                >
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <form
                                                onSubmit={this.onSubmit}
                                            >
                                                {showStep1AccountDetails && <Step1AccountDetails nextHandler={() => { this.showComponent("showStep2AccountProfessionalOccupationDetailsForm") }} backHandler={() => { }} registerFormType="1" setFormData={this.setFormData} getFormData={() => this.getFormData()} />}

                                                {showStep2AccountProfessionalOccupationDetailsForm && <Step2AccountProfessionalOccupationDetailsForm nextHandler={() => { this.showComponent("showStep3AccountProfessionalDetailForm") }} backHandler={() => { this.showComponent("showStep1AccountDetails") }} registerFormType="1" setFormData={this.setFormData} getFormData={() => this.getFormData()} />}

                                                {showStep3AccountProfessionalDetailForm && <Step3AccountProfessionalDetailForm nextHandler={() => { this.showComponent("showStep4AccountBillingAddressForm") }} backHandler={() => { this.showComponent("showStep2AccountProfessionalOccupationDetailsForm") }} registerFormType="1" setFormData={this.setFormData} getFormData={() => this.getFormData()} />}

                                                {showStep4AccountBillingAddressForm && <Step4AccountBillingAddressForm nextHandler={() => { this.showComponent("showStep5AccountShippingAddressForm") }} backHandler={() => { this.showComponent("showStep3AccountProfessionalDetailForm") }} registerFormType="1" setFormData={this.setFormData} getFormData={() => this.getFormData()} />}

                                                {showStep5AccountShippingAddressForm && <Step5AccountShippingAddressForm nextHandler={() => { this.showComponent("showStep6AccountProfessionalAgreementForm") }} backHandler={() => { this.showComponent("showStep4AccountBillingAddressForm") }} registerFormType="1" setFormData={this.setFormData} getFormData={() => this.getFormData()} />}

                                                {showStep6AccountProfessionalAgreementForm && <Step6AccountProfessionalAgreementForm nextHandler={() => { this.showComponent("showStep7AccountPasswordForm") }} backHandler={() => { this.showComponent("showStep5AccountShippingAddressForm") }} registerFormType="1" setFormData={this.setFormData} getFormData={() => this.getFormData()} />}

                                                {showStep7AccountPasswordForm && <Step7AccountPasswordForm nextHandler={() => { }} backHandler={() => { this.showComponent("showStep6AccountProfessionalAgreementForm") }} registerFormType="1" setFormData={this.setFormData} onSubmit={() => this.onSubmit(this.formData)} getFormData={() => this.getFormData()} />}
                                            </form>
                                            {errorMessage.length > 0 ? <span style={{
                                                padding: "1rem 1rem",
                                                marginBottom: "1rem",
                                                color: "#842029",
                                                backgroundColor: "#f8d7da",
                                                border: "1px solid #f5c2c7",
                                                borderRadius: "0.375rem",
                                                marginTop: "1rem",
                                                display: "block"
                                            }}>{errorMessage}</span>: ""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
