import React, { Component } from "react";
import '../ProfessionalRegister/ProfessionalRegister.scoped.scss';

export default class Step4AccountBillingAddressForm extends Component {
    constructor() {
        super();
        this.state = {
            name: "React",
            isFirstNameValid: true,
            isLastNameValid: true,
            isStreetAddressValid: true,
            isCityValid: true,
            isStateValid: true,
            isPostalCodeValid: true,
            isTelephoneValid: true,
            isMobileNumberValid: true
        }
    }

    render() {
        let formData = this.props.getFormData();
        return (
            <div
                id="account-billing-address-step"
                className="account-step"
            >
                <div className="row account-step-back">
                    <div className="col-xs-12">
                        <a onClick={this.props.backHandler}>
                            <span className="glyphicon glyphicon-chevron-left" />
                            BACK
                        </a>
                    </div>
                </div>
                <h2>Billing Address</h2>
                <div className="step-progress">
                    <div className="step-progress-label">
                        <span className="text">STEP</span>
                        <span className="number">
                            <span className="active">{this.props.registerFormType == "1" ? "4" : "2"}</span> / {this.props.registerFormType == "1" ? "7" : "5"}
                        </span>
                    </div>
                    <ul className="step-progress-bar">
                        <li className="done" />
                        {this.props.registerFormType == "1" ? <li className="done" /> : ""}
                        {this.props.registerFormType == "1" ? <li className="done" /> : ""}
                        <li className="active" />
                        <li className="" />
                        <li className="" />
                        <li className="" />
                    </ul>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <div className={this.state.isFirstNameValid ? "form-group" : "form-group has-error has-danger"}>
                            <label
                                className="control-label "
                                htmlFor="billingAddressForm.firstName"
                            >
                                First Name<span className="mandatory">*</span>
                            </label>
                            <input
                                id="billingAddressForm.firstName"
                                name="billingAddressForm.firstName"
                                className="js-billingAddressForm-firstName-input form-control js-required"
                                type="text"
                                required="required"
                                defaultValue={formData['attributes']['billingAddress.firstName']}
                                onChange={(e) => this.props.setFormData("billingAddress.firstName", e.target.value)}
                            />
                            {!this.state.isFirstNameValid ? <div className="help-block with-errors"><ul className="list-unstyled"><li>This is a required field</li></ul></div> : ""}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <div className={this.state.isLastNameValid ? "form-group" : "form-group has-error has-danger"}>
                            <label
                                className="control-label "
                                htmlFor="billingAddressForm.lastName"
                            >
                                Last Name<span className="mandatory">*</span>
                            </label>
                            <input
                                id="billingAddressForm.lastName"
                                name="billingAddressForm.lastName"
                                className="js-billingAddressForm-lastName-input form-control js-required"
                                data-pattern-error="Last Name invalid"
                                type="text"
                                required="required"
                                defaultValue={formData['attributes']['billingAddress.lastName']}
                                onChange={(e) => this.props.setFormData("billingAddress.lastName", e.target.value)}
                            />
                            {!this.state.isLastNameValid ? <div className="help-block with-errors"><ul className="list-unstyled"><li>This is a required field</li></ul></div> : ""}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <div className={this.state.isStreetAddressValid ? "form-group" : "form-group has-error has-danger"}>
                            <label
                                className="control-label "
                                htmlFor="billingAddressForm.line1-new"
                            >
                                Street Address and Unit Number (PO Boxes not
                                allowed)<span className="mandatory">*</span>
                            </label>
                            <input
                                id="billingAddressForm.line1-new"
                                name="billingAddressForm.line1"
                                className="js-billingAddressForm-line1-input form-control js-required"
                                data-pattern-error="Please limit number of characters to 35"
                                pattern="^.{1,35}$"
                                type="text"
                                maxLength={35}
                                required="required"
                                defaultValue={formData['attributes']['billingAddress.streetAddress']}
                                onChange={(e) => this.props.setFormData("billingAddress.streetAddress", e.target.value)}
                            />
                            {!this.state.isStreetAddressValid ? <div className="help-block with-errors"><ul className="list-unstyled"><li>This is a required field</li></ul></div> : ""}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-md-10">
                        <div className={this.state.isCityValid ? "form-group" : "form-group has-error has-danger"}>
                            <label
                                className="control-label "
                                htmlFor="billingAddressForm.townCity"
                            >
                                City<span className="mandatory">*</span>
                            </label>
                            <input
                                id="billingAddressForm.townCity"
                                name="billingAddressForm.townCity"
                                className="js-billingAddressForm-townCity-input form-control js-required"
                                data-pattern-error="Please limit number of characters to 35"
                                pattern="^.{1,35}$"
                                type="text"
                                maxLength={35}
                                required="required"
                                defaultValue={formData['attributes']['billingAddress.city']}
                                onChange={(e) => this.props.setFormData("billingAddress.city", e.target.value)}
                            />
                            {!this.state.isCityValid ? <div className="help-block with-errors"><ul className="list-unstyled"><li>This is a required field</li></ul></div> : ""}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-md-10">
                        <div className={this.state.isStateValid ? "form-group" : "form-group has-error has-danger"}>
                            <label
                                className="control-label "
                                htmlFor="billingAddressForm.state"
                            >
                                State<span className="skip"></span>
                                <span className="mandatory">*</span>
                            </label>
                            <div className="control">
                                <select
                                    id="billingAddressForm.state"
                                    name="billingAddressForm.state"
                                    className="form-control js-required"
                                    required="required"
                                    defaultValue={formData['attributes']['billingAddress.state']}
                                    onChange={(e) => this.props.setFormData("billingAddress.state", e.target.value)}
                                >
                                    <option
                                        value=""
                                        disabled="disabled"
                                        data-select2-id={970}
                                    >
                                        Choose your state
                                    </option>
                                    <option value="US-AL">Alabama</option>
                                    <option value="US-AZ">Arizona</option>
                                    <option value="US-AR">Arkansas</option>
                                    <option value="US-CA">California</option>
                                    <option value="US-CO">Colorado</option>
                                    <option value="US-CT">Connecticut</option>
                                    <option value="US-DE">Delaware</option>
                                    <option value="US-DC">
                                        District of Columbia
                                    </option>
                                    <option value="US-FL">Florida</option>
                                    <option value="US-GA">Georgia</option>
                                    <option value="US-ID">Idaho</option>
                                    <option value="US-IL">Illinois</option>
                                    <option value="US-IN">Indiana</option>
                                    <option value="US-IA">Iowa</option>
                                    <option value="US-KS">Kansas</option>
                                    <option value="US-KY">Kentucky</option>
                                    <option value="US-LA">Louisiana</option>
                                    <option value="US-ME">Maine</option>
                                    <option value="US-MD">Maryland</option>
                                    <option value="US-MA">Massachusetts</option>
                                    <option value="US-MI">Michigan</option>
                                    <option value="US-MN">Minnesota</option>
                                    <option value="US-MS">Mississippi</option>
                                    <option value="US-MO">Missouri</option>
                                    <option value="US-MT">Montana</option>
                                    <option value="US-NE">Nebraska</option>
                                    <option value="US-NV">Nevada</option>
                                    <option value="US-NH">New Hampshire</option>
                                    <option value="US-NJ">New Jersey</option>
                                    <option value="US-NM">New Mexico</option>
                                    <option value="US-NY">New York</option>
                                    <option value="US-NC">North Carolina</option>
                                    <option value="US-ND">North Dakota</option>
                                    <option value="US-OH">Ohio</option>
                                    <option value="US-OK">Oklahoma</option>
                                    <option value="US-OR">Oregon</option>
                                    <option value="US-PA">Pennsylvania</option>
                                    <option value="US-RI">Rhode Island</option>
                                    <option value="US-SC">South Carolina</option>
                                    <option value="US-SD">South Dakota</option>
                                    <option value="US-TN">Tennessee</option>
                                    <option value="US-TX">Texas</option>
                                    <option value="US-UT">Utah</option>
                                    <option value="US-VT">Vermont</option>
                                    <option value="US-VA">Virginia</option>
                                    <option value="US-WA">Washington</option>
                                    <option value="US-WV">West Virginia</option>
                                    <option value="US-WI">Wisconsin</option>
                                    <option value="US-WY">Wyoming</option>
                                </select>
                            </div>
                            {!this.state.isStateValid ? <div className="help-block with-errors"><ul className="list-unstyled"><li>This is a required field</li></ul></div> : ""}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-md-4">
                        <div className={this.state.isPostalCodeValid ? "form-group" : "form-group has-error has-danger"}>
                            <label
                                className="control-label "
                                htmlFor="billingAddressForm.postalCode"
                            >
                                ZIP/Postal Code<span className="mandatory">*</span>
                            </label>
                            <input
                                id="billingAddressForm.postalCode"
                                name="billingAddressForm.postalCode"
                                className="js-billingAddressForm-postalCode-input form-control js-required"
                                data-pattern-error="Please enter a valid zip/postal code"
                                type="text"
                                required="required"
                                defaultValue={formData['attributes']['billingAddress.postalCode']}
                                onChange={(e) => this.props.setFormData("billingAddress.postalCode", e.target.value)}
                            />
                            {!this.state.isPostalCodeValid ? <div className="help-block with-errors"><ul className="list-unstyled"><li>This is a required field</li></ul></div> : ""}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <div className={this.state.isTelephoneValid ? "form-group" : "form-group has-error has-danger"}>
                            <div className="phone-box-wraper">
                                <label
                                    className="control-label"
                                    htmlFor="billingAddressForm.telephone"
                                >
                                    Telephone<span className="mandatory">*</span>
                                </label>
                                <input
                                    id="billingAddressForm.telephone"
                                    name="billingAddressForm.telephone"
                                    className="js-billingAddressForm-telephone-input form-control js-required"
                                    data-pattern-error="Please enter a valid telephone"
                                    pattern="^[\d]+$"
                                    type="text"
                                    required="required"
                                    defaultValue={formData['attributes']['billingAddress.telephone']}
                                    onChange={(e) => this.props.setFormData("billingAddress.telephone", e.target.value)}
                                />
                                <span className="spacer">
                                    <span className="spacer-inner" />
                                </span>
                                <div className="control js-phone-prefix-select billingAddressForm.telephonePrefixCountry">
                                    <span
                                        className="select2 select2-container select2-container--default"
                                        dir="ltr"
                                        data-select2-id={1}
                                        style={{ width: 70 }}
                                    >
                                        <span className="selection">
                                            <span
                                                className="select2-selection select2-selection--single"
                                                role="combobox"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                tabIndex={0}
                                                aria-disabled="false"
                                                aria-labelledby="select2-billingAddressFormtelephonePrefixCountry-container"
                                            >
                                                <span
                                                    className="select2-selection__rendered"
                                                    id="select2-billingAddressFormtelephonePrefixCountry-container"
                                                    role="textbox"
                                                    aria-readonly="true"
                                                    title="United States"
                                                >
                                                    US
                                                </span>
                                                <span
                                                    className="select2-selection__arrow"
                                                    role="presentation"
                                                >
                                                    <b role="presentation" />
                                                </span>
                                            </span>
                                        </span>
                                        <span
                                            className="dropdown-wrapper"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className="info-block">
                                <i className="nc-icon-info-circle" />A primary phone
                                number is required to contact you for shipping
                                related inquiries only
                            </div>
                            {!this.state.isTelephoneValid ? <div className="help-block with-errors"><ul className="list-unstyled"><li>This is a required field</li></ul></div> : ""}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <div className={this.state.isMobileNumberValid ? "form-group" : "form-group has-error has-danger"}>
                            <div className="phone-box-wraper">
                                <label
                                    className="control-label"
                                    htmlFor="billingAddressForm.mobile"
                                >
                                    Mobile
                                    <span className="optional">&nbsp;(optional)</span>
                                </label>
                                <input
                                    id="billingAddressForm.mobile"
                                    name="billingAddressForm.mobile"
                                    className="js-billingAddressForm-mobile-input form-control js-optional"
                                    type="text"
                                    defaultValue={formData['attributes']['billingAddress.mobileNumber']}
                                    onChange={(e) => this.props.setFormData("billingAddress.mobileNumber", e.target.value)}
                                />
                                <span className="spacer">
                                    <span className="spacer-inner" />
                                </span>
                                <div className="control js-phone-prefix-select billingAddressForm.mobilePrefixCountry">
                                    <span
                                        className="select2 select2-container select2-container--default"
                                        dir="ltr"
                                        data-select2-id={243}
                                        style={{ width: 70 }}
                                    >
                                        <span className="selection">
                                            <span
                                                className="select2-selection select2-selection--single"
                                                role="combobox"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                tabIndex={0}
                                                aria-disabled="false"
                                                aria-labelledby="select2-billingAddressFormmobilePrefixCountry-container"
                                            >
                                                <span
                                                    className="select2-selection__rendered"
                                                    id="select2-billingAddressFormmobilePrefixCountry-container"
                                                    role="textbox"
                                                    aria-readonly="true"
                                                    title="United States"
                                                >
                                                    US
                                                </span>
                                                <span
                                                    className="select2-selection__arrow"
                                                    role="presentation"
                                                >
                                                    <b role="presentation" />
                                                </span>
                                            </span>
                                        </span>
                                        <span
                                            className="dropdown-wrapper"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className="info-block">
                                <i className="nc-icon-info-circle" />
                                Adding a mobile number will help our customer
                                service and delivery partners reach you in case of
                                need
                            </div>
                            {!this.state.isMobileNumberValid ? <div className="help-block with-errors"><ul className="list-unstyled"><li>This is a required field</li></ul></div> : ""}
                        </div>
                    </div>
                </div>
                <div className="row account-step-buttons">
                    <span className="hidden-xs hidden-sm" style={{ width: 'auto' }}>
                        <div className="col-xs-12 col-md-auto">
                            <a
                                onClick={this.props.backHandler}
                                className="btn btn-default custom-cancel-btn btn-block account-btn-prev"
                            >
                                BACK
                            </a>
                        </div>
                    </span>
                    <div className="col-xs-12 col-md-auto">
                        <a
                            onClick={() => { if (this.validateForm()) { this.props.nextHandler() } }}
                            className="btn btn-primary btn-block account-btn-next "
                            data-validate-customer-url="/gigya-register/validateCustomer"
                        >
                            <span className="normal">NEXT</span>
                            <span className="review">REVIEW MY DETAILS</span>
                        </a>
                    </div>
                    <div className="col-xs-12 col-md-auto visible-xs visible-sm">
                        <a
                            onClick={this.props.backHandler}
                            className="btn btn-default custom-cancel-btn btn-block account-btn-prev"
                        >
                            BACK
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    validateForm() {
        let formData = this.props.getFormData();
        let hasError = false;
        if (formData['attributes']['billingAddress.firstName'] === "") {
            this.setState({ isFirstNameValid: false });
            hasError = true;
        } else {
            this.setState({ isFirstNameValid: true });
        }
        if (formData['attributes']['billingAddress.lastName'] === "") {
            this.setState({ isLastNameValid: false });
            hasError = true;
        } else {
            this.setState({ isLastNameValid: true });
        }
        if (formData['attributes']['billingAddress.streetAddress'] === "") {
            this.setState({ isStreetAddressValid: false });
            hasError = true;
        } else {
            this.setState({ isStreetAddressValid: true });
        }
        if (formData['attributes']['billingAddress.city'] === "") {
            this.setState({ isCityValid: false });
            hasError = true;
        } else {
            this.setState({ isCityValid: true });
        }
        if (formData['attributes']['billingAddress.state'] === "") {
            this.setState({ isStateValid: false });
            hasError = true;
        } else {
            this.setState({ isStateValid: true });
        }
        if (formData['attributes']['billingAddress.postalCode'] === "") {
            this.setState({ isPostalCodeValid: false });
            hasError = true;
        } else {
            this.setState({ isPostalCodeValid: true });
        }
        if (formData['attributes']['billingAddress.telephone'] === "") {
            this.setState({ isTelephoneValid: false });
            hasError = true;
        } else {
            this.setState({ isTelephoneValid: true });
        }

        if (hasError) return false;
        return true;
    }
}