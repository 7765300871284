import React, { Component } from "react";
import '../ProfessionalRegister/ProfessionalRegister.scoped.scss';

export default class Step3AccountProfessionalDetailForm extends Component {
    constructor() {
        super();
        this.state = {
            name: "React",
            isBusinessNameValid: true,
            isProfessionalLicenceNumberValid: true,
            isSalesRepresentativeNameValid: true
        }
    }

    render() {
        let formData = this.props.getFormData();
        return (
            <div
                id="account-professional-detail-step"
                className="account-step"
            >
                <div className="row account-step-back">
                    <div className="col-xs-12">
                        <a onClick={this.props.backHandler}>
                            <span className="glyphicon glyphicon-chevron-left" />
                            BACK
                        </a>
                    </div>
                </div>
                <h2>Professional Details</h2>
                <div className="step-progress">
                    <div className="step-progress-label">
                        <span className="text">STEP</span>
                        <span className="number">
                            <span className="active">3</span> / { this.props.registerFormType == "1" ? "7" : "5"}
                        </span>
                    </div>
                    <ul className="step-progress-bar">
                        <li className="done" />
                        <li className="done" />
                        <li className="active" />
                        <li className="" />
                        <li className="" />
                        <li className="" />
                        <li className="" />
                    </ul>
                </div>
                <div
                    className="occupation-detail"
                    id="occupation-salon-owner"
                    style={{display: 'block'}}
                >
                    <div className="row">
                        <div className="col-xs-12">
                            <h3>Salon Owner ordering for multiple stylists</h3>
                            <span
                                className="tempIcon"
                                style={{ display: "none" }}
                            >
                                <i className="nc-icon-salon-owner" />
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-10">
                            <div className={this.state.isBusinessNameValid ? "form-group" : "form-group has-error has-danger"}>
                                <label
                                    className="control-label "
                                    htmlFor="salonOwner.businessName"
                                >
                                    Business Name<span className="mandatory">*</span>
                                </label>
                                <input
                                    id="salonOwner.businessName"
                                    name="salonOwner.businessName"
                                    className="js-salonOwner-businessName-input form-control js-required"
                                    type="text"
                                    required="required"
                                    defaultValue={formData['attributes']['businessName']}
                                    onChange={(e) => this.props.setFormData("businessName", e.target.value)}
                                />
                                { !this.state.isBusinessNameValid ? <div className="help-block with-errors"><ul className="list-unstyled"><li>This is a required field</li></ul></div> : ""}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-10">
                            <div className={this.state.isProfessionalLicenceNumberValid ? "form-group" : "form-group has-error has-danger"}>
                                <label
                                    className="control-label "
                                    htmlFor="salonOwner.professionalLicenseNumber"
                                >
                                    Professional license number
                                    <span className="mandatory">*</span>
                                </label>
                                <input
                                    id="salonOwner.professionalLicenseNumber"
                                    name="salonOwner.professionalLicenseNumber"
                                    className="js-salonOwner-professionalLicenseNumber-input form-control js-required"
                                    type="text"
                                    required="required"
                                    defaultValue={formData['attributes']['professionalLicenceNumber']}
                                    onChange={(e) => this.props.setFormData("professionalLicenceNumber", e.target.value)}
                                />
                                { !this.state.isProfessionalLicenceNumberValid ? <div className="help-block with-errors"><ul className="list-unstyled"><li>This is a required field</li></ul></div> : ""}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-10">
                            <div className={this.state.isSalesRepresentativeNameValid ? "form-group" : "form-group has-error has-danger"}>
                                <label
                                    className="control-label "
                                    htmlFor="salonOwner.salesRepName"
                                >
                                    Sales rep name
                                    <span className="optional">&nbsp;(optional)</span>
                                </label>
                                <input
                                    id="salonOwner.salesRepName"
                                    name="salonOwner.salesRepName"
                                    className="js-salonOwner-salesRepName-input form-control js-optional"
                                    type="text"
                                    defaultValue={formData['attributes']['salesRepresentativeName']}
                                    onChange={(e) => this.props.setFormData("salesRepresentativeName", e.target.value)}
                                />
                                { !this.state.isSalesRepresentativeNameValid ? <div className="help-block with-errors"><ul className="list-unstyled"><li>This is a required field</li></ul></div> : ""}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row account-step-buttons">
                    <span className="hidden-xs hidden-sm" style={{width: 'auto'}}>
                        <div className="col-xs-12 col-md-auto">
                            <a
                                onClick={this.props.backHandler}
                                className="btn btn-default custom-cancel-btn btn-block account-btn-prev"
                            >
                                BACK
                            </a>
                        </div>
                    </span>
                    <div className="col-xs-12 col-md-auto">
                        <a
                            onClick={() => {if (this.validateForm()) {this.props.nextHandler()}}}
                            className="btn btn-primary btn-block account-btn-next "
                            data-validate-customer-url="/gigya-register/validateCustomer"
                        >
                            <span className="normal">NEXT</span>
                            <span className="review">REVIEW MY DETAILS</span>
                        </a>
                    </div>
                    <div className="col-xs-12 col-md-auto visible-xs visible-sm">
                        <a
                            onClick={this.props.backHandler}
                            className="btn btn-default custom-cancel-btn btn-block account-btn-prev"
                        >
                            BACK
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    validateForm() {
        let formData = this.props.getFormData();
        let hasError = false;
        if (formData['attributes']['businessName'] === "") {
            this.setState({isBusinessNameValid: false});
            hasError = true;
        } else {
            this.setState({isBusinessNameValid: true});
        }
        if (formData['attributes']['professionalLicenceNumber'] === "") {
            this.setState({isProfessionalLicenceNumberValid: false});
            hasError = true;
        } else {
            this.setState({isProfessionalLicenceNumberValid: true});
        }
        /*if (formData['attributes']['salesRepresentativeName'] === "") {
            this.setState({isSalesRepresentativeNameValid: false});
            hasError = true;
        } else {
            this.setState({isSalesRepresentativeNameValid: true});
        }*/

        if (hasError) return false;
        return true;
    }
}
