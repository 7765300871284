import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AuthContext } from '../../providers/AuthProvider';
import useToken from '../../useToken';
import './Header.scoped.scss';

function Header() {
    const { deleteToken } = useToken();
    const authService = React.useContext(AuthContext);
    const isAuthenticated = authService.isAuthenticated();
    const history = useHistory();

    return (
      <div className="page-login pageType-ContentPage template-pages-LoginPageTemplate pageLabel-login smartedit-page-uid-login smartedit-page-uuid-eyJpdGVtSWQiOiJsb2dpbiIsImNhdGFsb2dJZCI6ImNvdHktdXNDb250ZW50Q2F0YWxvZyIsImNhdGFsb2dWZXJzaW9uIjoiT25saW5lIn0= smartedit-catalog-version-uuid-coty-usContentCatalog/Online  language-en">
        <div className="yCmsContentSlot container">
          <div className="content">
            <link href="https://prostore.scissorboy.com/css/digital.css?v=102" media="all" rel="stylesheet" type="text/css" />
          </div>
          <div className="content">
            <div className="topNavContainer">
              <div className="topNav">
                <div className="notice">
                  <p>FREE SHIPPING ON ALL ORDERS $50 OR MORE</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <header className="js-mainHeader ">
          <nav className="navigation navigation--top hidden-xs hidden-sm">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-8">
                  <div className="nav__right">
                    <ul className="nav__links nav__links--account">
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </nav>
          <div className="sticky-header-section">
            <nav className="navigation navigation--middle js-navigation--middle">
              <div className="container">
                <div className="row">
                  <div className="mobile__nav__row mobile__nav__row--table">
                    <div className="mobile__nav__row--table-group">
                      <div className="mobile__nav__row--table-row">
                        <div className="mobile__nav__row--table-cell mobile__nav__row--first visible-xs hidden-sm">
                          <button className="mobile__nav__row--btn btn mobile__nav__row--btn-menu js-toggle-sm-navigation burger-first" type="button">
                            <span className="custom-burger-icon" />
                          </button>
                          <div className="lang-selector">
                          </div>
                        </div>
                        <div className="mobile__nav__row--table-cell mobile__nav__row--center hidden-sm hidden-md hidden-lg">
                          <a href="/">
                          </a>
                          <div className="yCmsContentSlot"><a href="/">
                            </a>
                            <div className="mobile-logo"><a href="/" />
                              <div className="banner__component simple-banner"><a href="/">
                                </a><a href="/"><img alt="" src="https://media.wella.professionalstore.com/medias/wella-logo.jpg?context=bWFzdGVyfGltYWdlc3wxMDgzM3xpbWFnZS9qcGVnfGltYWdlcy9oODEvaDBhLzkyMzUxODQ1MTcxNTAuanBnfGE5MmFiMjBkNzZmNTM0NzM2NjEwYjc3ODllZDBiMWFmYTMyMDZlMzQxNGRjMzdkOWVjY2EyNDhhNzk4NzVkNDc" /></a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="yCmsContentSlot miniCartSlot componentContainer mobile__nav__row--table-cell mobile__nav__row--last hidden-sm hidden-md hidden-lg">
                          <div className="yCmsContentSlot miniCartSlot componentContainer mobile__nav__row--table hidden-sm hidden-md hidden-lg">
                            <div className="yCmsComponent mobile__nav__row--table-cell">
                              <div className="nav-order-tools js-nav-order-tools display-none NAVcompONENT" data-title>
                                <nav className="nav-order-tools__child-wrap display-none">
                                  <div>
                                    <a href="/search/advanced" title="Order Forms">Order
                                      Forms</a>
                                  </div>
                                  <div>
                                    <a href="/import/csv/saved-cart" title="Import Saved Cart">Import Saved Cart</a>
                                  </div>
                                </nav>
                              </div>
                            </div>
                            <div className="yCmsComponent mobile__nav__row--table-cell">
                              <div className="nav-favorite">
                                <a href="/wishlist/get-wish-list-entries" className="mini-favorite-link js-mini-favorite-link">
                                  <div className="mini-favorite-icon">
                                    <span className="glyphicon glyphicon-heart"> </span>
                                  </div>
                                </a>
                              </div>
                              <div className="nav-cart">
                                <a href="/cart" className="mini-cart-link js-mini-cart-link pull-left" data-mini-cart-url="/cart/rollover/MiniCart" data-mini-cart-refresh-url="/cart/miniCart/SUBTOTAL" data-mini-cart-auth-url="/cart/authCheck" data-mini-cart-login-url="/login" data-mini-cart-name="Cart" data-mini-cart-empty-name="Empty Cart" data-mini-cart-items-text="Items">
                                  <div className="mini-cart-icon">
                                    <span className="glyphicon glyphicon-shopping-cart " />
                                    <div className="mini-cart-count js-mini-cart-count"><span className="nav-items-total">0<span className="items-desktop hidden">
                                        </span></span></div>
                                  </div>
                                </a>
                                <div className="mini-cart-container js-mini-cart-container" />
                              </div>
                            </div>
                          </div><button className="mobile__nav__row--btn btn mobile__nav__row--btn-search js-toggle-xs-search hidden-sm hidden-md hidden-lg" type="button">
                            <span className="glyphicon glyphicon-search" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row desktop__nav">
                  <div className="nav__left col-xs-12 col-sm-12">
                    <div className="row">
                      <div className="col-sm-2 hidden-xs visible-sm mobile-menu">
                        <button className="btn js-toggle-sm-navigation" type="button">
                          <span className="custom-burger-icon" />
                        </button>
                      </div>
                      <div className="col-md-5">
                        <div className="site-search">
                          <div className="yCmsComponent">
                            <div className="ui-front">
                              <form name="search_form_SearchBox" method="get" action="/search/">
                                <div className="input-group">
                                  <input type="text" id="js-site-search-input" className="form-control js-site-search-input ui-autocomplete-input" name="text" maxLength={100} placeholder="Search" data-options="{&quot;autocompleteUrl&quot; : &quot;/search/autocomplete/SearchBox&quot;,&quot;minCharactersBeforeRequest&quot; : &quot;2&quot;,&quot;waitTimeBeforeRequest&quot; : &quot;500&quot;,&quot;displayProductImages&quot; : true}" autoComplete="off" />
                                  <span className="input-group-btn">
                                    <button className="btn btn-link js_search_button hidden-sm hidden-xs" type="submit" disabled>
                                      <span className="glyphicon glyphicon-search" />
                                    </button>
                                  </span>
                                </div>
                              </form>
                              <button type="button" className="js-toggle-xs-search site-search-close"><span className="icon icon-delete2" /></button>
                              <ul id="ui-id-1" tabIndex={0} className="ui-menu ui-widget ui-widget-content ui-autocomplete ui-front" style={{display: 'none', top: '68px'}} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mobile__nav__row--tables yCmsComponent col-sm-offset-1 col-sm-6 col-md-offset-0 col-md-2 hidden-xs">
                        <a href="/">
                        </a>
                        <div className="yCmsContentSlot"><a href="/">
                          </a>
                          <h1 className="site-logo"><a href="/" />
                            <div className="banner__component simple-banner"><a href="/">
                              </a><a href="/"><img alt="" src="https://media.wella.professionalstore.com/medias/wella-logo.jpg?context=bWFzdGVyfGltYWdlc3wxMDgzM3xpbWFnZS9qcGVnfGltYWdlcy9oODEvaDBhLzkyMzUxODQ1MTcxNTAuanBnfGE5MmFiMjBkNzZmNTM0NzM2NjEwYjc3ODllZDBiMWFmYTMyMDZlMzQxNGRjMzdkOWVjY2EyNDhhNzk4NzVkNDc" /></a>
                            </div>
                          </h1>
                        </div>
                      </div>
                      <div className="nav__right col-xs-3 col-md-5 hidden-xs">
                        <button className="mobile__nav__row--btn btn mobile__nav__row--btn-search js-toggle-xs-search hidden-lg hidden-md" type="button">
                          <span className="glyphicon glyphicon-search" />
                        </button>
                        <ul className="nav__links nav__links--account pull-right hidden-xs">
                        {!isAuthenticated ? <li className="liOffcanvas sign-in-container hidden-sm">
                            <Link to="/login">
                              <span className="custom-user-icon-for-login" />
                              <span className="sign-in-container-text">
                                Sign In</span>
                            </Link>
                          </li> : ""}
                          {!isAuthenticated ? <li className="liOffcanvas register-container hidden-sm">
                            <span className="register-seperator">|</span>
                            <Link to="/register">
                              <span className="register-container-text">
                                Create Account</span>
                            </Link>
                          </li> : ""}
                          {!isAuthenticated ? <li className="liOffcanvas register-container hidden-sm">
                            <span className="register-seperator">|</span>
                            <Link to="/wellaverse">
                              <span className="register-container-text">
                                Wellaverse</span>
                            </Link>
                          </li> : ""}
                          {isAuthenticated ? <li className="liOffcanvas register-container hidden-sm">
                            <span className="register-seperator">|</span>
                            <a style={{cursor: "pointer"}} onClick={() => { history.push("/logout") }}>
                              <span className="register-container-text">
                                Signout</span>
                            </a>
                          </li> : ""}
                        </ul>
                        <ul className="nav__links nav__links--shop_info pull-right">
                          <li className="lang-translator">
                          </li>
                          <li>
                            <div className="yCmsContentSlot componentContainer">
                              <div className="yCmsComponent">
                                <div className="nav-order-tools js-nav-order-tools display-none NAVcompONENT" data-title>
                                  <nav className="nav-order-tools__child-wrap display-none">
                                    <div>
                                      <a href="/search/advanced" title="Order Forms">Order
                                        Forms</a>
                                    </div>
                                    <div>
                                      <a href="/import/csv/saved-cart" title="Import Saved Cart">Import Saved Cart</a>
                                    </div>
                                  </nav>
                                </div>
                              </div>
                              <div className="yCmsComponent">
                                <div className="nav-favorite">
                                  <a href="/wishlist/get-wish-list-entries" className="mini-favorite-link js-mini-favorite-link">
                                    <div className="mini-favorite-icon">
                                      <span className="glyphicon glyphicon-heart"> </span>
                                    </div>
                                  </a>
                                </div>
                                <div className="nav-cart">
                                  <a href="/cart" className="mini-cart-link js-mini-cart-link pull-left" data-mini-cart-url="/cart/rollover/MiniCart" data-mini-cart-refresh-url="/cart/miniCart/SUBTOTAL" data-mini-cart-auth-url="/cart/authCheck" data-mini-cart-login-url="/login" data-mini-cart-name="Cart" data-mini-cart-empty-name="Empty Cart" data-mini-cart-items-text="Items">
                                    <div className="mini-cart-icon">
                                      <span className="glyphicon glyphicon-shopping-cart " />
                                      <div className="mini-cart-count js-mini-cart-count"><span className="nav-items-total">0<span className="items-desktop hidden">
                                          </span></span></div>
                                    </div>
                                  </a>
                                  <div className="mini-cart-container js-mini-cart-container" />
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
            <a id="skiptonavigation" />
            <nav className="navigation navigation--bottom js_navigation--bottom js-enquire-offcanvas-navigation container navigation--bottom--register" role="navigation">
              <ul className="sticky-nav-top hidden-lg hidden-md js-sticky-user-group hidden-md hidden-lg">
                <li className="auto liUserSign"><a className="userSign" href="https://us.wella.professionalstore.com/login">
                    <span className="custom-user-icon-for-login" />
                    <span className="sign-in-container-text">
                      Sign In</span>
                  </a>
                  <div className="close-nav"><button type="button" className="js-toggle-sm-navigation btn"><span className="icon icon-delete2" /></button></div>
                </li>
              </ul>
              <ul className="register-list hidden-lg hidden-md ">
                <li className="liOffcanvas register-container">
                  <a href="/register">Create Account</a>
                </li>
              </ul>
              <div className="navigation__overflow add-Nav-Arrow">
                <ul data-trigger="#signedInUserOptionsToggle" className="nav__links nav__links--products nav__links--mobile js-userAccount-Links js-nav-collapse-body offcanvasGroup1 collapse hidden-md hidden-lg" style={{height: '0px'}}>
                </ul>
                <ul className="nav__links nav__links--products js-offcanvas-links mega-menu">
                  <li className="auto nav__links--primary nav__links--primary-has__sub js-enquire-has-sub mega-menu-item">
                    <span className=" yCmsComponent nav__link js_nav__link">
                      <a href="/WellaMainLP" title="Wella Pro">Wella Pro<span className="glyphicon glyphicon-menu-down hidden-sm hidden-xs" /></a></span><span className="glyphicon  glyphicon-menu-right hidden-md hidden-lg nav__link--drill__down js_nav__link--drill__down" />
                    <div className="sub__navigation js_sub__navigation col-md-12" style={{top: '176.297px', left: '0px', right: 'auto', marginTop: '0px'}}>
                      <a className="sm-back js-enquire-sub-close hidden-md hidden-lg" href="#">Back</a>
                      <div className="row">
                        <div className="container">
                          <div className="sub-navigation-section col-md-2">
                            <div className="title">Color</div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/koleston-perfect" title="Koleston Perfect">Koleston
                                  Perfect</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/color-touch" title="Color Touch">Color Touch</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/illumina-color" title="Illumina Color">Illumina Color</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/shinefinity" title="Shinefinity">Shinefinity</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/permanent-color/color-perfect-line-302200089800003541" title="Color Perfect">Color Perfect</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/demi-permanent-color/midway-line-302200089900003542" title="Midway Couture">Midway Couture</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/semi-permanent-color/color-fresh-line-302200089200003535" title="Color Fresh">Color Fresh</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/semi-permanent-color/color-fresh-create-line-302200089200004629" title="Color Fresh Create">Color Fresh Create</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/blondor" title="Blondor">Blondor</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/blondor-toners" title="Blondor Toners">Blondor Toners</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/blondor-base-breaker" title="Blondor Base Breakers">Blondor Base Breakers</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/blondorplex" title="BlondorPlex">BlondorPlex</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/lightener/blondor-freelights" title="Blondor Freelights">Blondor Freelights</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/lightener/magma-by-blondor-line-302200088800003800" title="Magma by Blondor">Magma by Blondor</a>
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <div className="title">Color Categories</div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/en/Wella-Professionals/Permanent-Color/c/BRAND_3022000890" title="Permanent Color">Permanent Color</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/BRAND_3022000891" title="Demi-Permanent Color">Demi-Permanent Color</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/demi-permanent-color/shinefinity-zero-lift-glaze" title="Color Glaze">Color Glaze</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/BRAND_3022000892" title="Semi-Permanent Color">Semi-Permanent Color</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/toner-brand-3022000941" title="Toners">Toners</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/developer-brand-3022000894" title="Developers">Developers</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/color/wella-professional-emulsion" title="Emulsions">Emulsions</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/BRAND_3022000888" title="Lightener">Lightener</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/color-additive-brand-3022000897" title="WellaPlex">WellaPlex</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/care/service" title="Color Service">Color Service</a>
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <div className="yCmsComponent l2Category">
                              <a href="/wella/care" title="Care">Care</a>
                            </div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/care-brand-3022000901" title="All Care">All Care</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/color-fresh-masks" title="Color Fresh Masks">Color Fresh
                                  Masks</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/invigo" title="All INVIGO">All INVIGO</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/care/invigo-brilliance" title="INVIGO Brilliance">INVIGO Brilliance</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/care/invigo-nutri-enrich-line-302200090100003945" title="INVIGO Nutri-Enrich">INVIGO Nutri-Enrich</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/care/invigo-volume-boost-" title="INVIGO Volume Boost">INVIGO Volume Boost</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/care/invigo-balance-line-302200090100003944" title="INVIGO Balance">INVIGO Balance</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/care/blonde-recharge" title="INVIGO Blonde Recharge">INVIGO Blonde Recharge</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302200090100003948" title="ColorMotion+">ColorMotion+</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/care/fusionplex" title="FUSIONPLEX">FUSIONPLEX</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/care/nutricurls-line-302200090100003946" title="Nutricurls">Nutricurls</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/care/oil-reflections" title="Oil Reflections">Oil Reflections</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/elements" title="Elements">Elements</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302200090100003921" title="Service">Service</a>
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <div className="yCmsComponent l2Category">
                              <a href="/category/wella-professionals/styling-brand-3022000904" title="Styling">Styling</a>
                            </div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/styling-brand-3022000904" title="All Styling">All Styling</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302200090400003941" title="Hairspray">Hairspray</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/styling/nutricurls" title="Nutricurls">Nutricurls</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302200090400003945" title="Volume">Volume</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302200090400003943" title="Smooth">Smooth</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302200090400003944" title="Texture">Texture</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302200090400003942" title="Shine">Shine</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302200090400003549" title="Oil">Oil</a>
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <div className="yCmsComponent l2Category">
                              <a href="/category/wella-professionals/accessories-brand-3022000940" title="Accessories">Accessories</a>
                            </div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/BRAND_3022000940" title="All Accessories">All Accessories</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/PRODSUBCAT_110208" title="Brushes">Brushes</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/accessories/foils" title="Foils">Foils</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/accessories/color-cube" title="Color Cube Organizers">Color Cube Organizers</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/accessories/apron" title="Apron">Apron</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/accessories/pump" title="Pumps">Pumps</a>
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <ul className="sub-navigation-list ">
                              <li className="third-navigation-node">
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <ul className="sub-navigation-list ">
                              <li className="third-navigation-node last-child-navigation-list">
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="auto nav__links--primary nav__links--primary-has__sub js-enquire-has-sub mega-menu-item">
                    <span className=" yCmsComponent nav__link js_nav__link">
                      <a href="/sebastian" title="Sebastian Pro">Sebastian Pro<span className="glyphicon glyphicon-menu-down hidden-sm hidden-xs" /></a></span><span className="glyphicon  glyphicon-menu-right hidden-md hidden-lg nav__link--drill__down js_nav__link--drill__down" />
                    <div className="sub__navigation js_sub__navigation col-md-12" style={{top: '176.297px', marginTop: '0px'}}>
                      <a className="sm-back js-enquire-sub-close hidden-md hidden-lg" href="#">Back</a>
                      <div className="row">
                        <div className="container">
                          <div className="sub-navigation-section col-md-2">
                            <div className="yCmsComponent l2Category">
                              <a href="/category/sebastian-professional/care-brand-3022601090" title="Care">Care</a>
                            </div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/BRAND_3022601090" title="All Care">All Care</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/sebastian-professional/care/dark-oil-line-302260092600003890" title="Dark Oil">Dark Oil</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302260092600003835" title="Drench">Drench</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302260092600003833" title="Penetraitt">Penetraitt</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/sebastian-professional/care/light" title="Light">Light</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302260092600003838" title="Volupt">Volupt</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302260092600003889" title="Reset Preset">Reset Preset</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302260092600003842" title="Twisted">Twisted</a>
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <div className="yCmsComponent l2Category">
                              <a href="/category/sebastian-professional/styling-brand-3022600926" title="Styling">Styling</a>
                            </div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/BRAND_3022600926" title="All Styling">All Styling</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302260092600003841" title="Hairspray">Hairspray</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302260092600003843" title="Dark Oil">Dark Oil</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302260092600004654" title="Curls">Curls</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302260092600004651" title="Nourish">Nourish</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302260092600004653" title="Texture">Texture</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302260092600004652" title="Shine">Shine</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/Sebastian-Professional-Volume/c/sebastian-volume" title="Volume">Volume</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/product/shaper-id/000000099240068006" title="Shaper iD">Shaper iD</a>
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <div className="yCmsComponent l2Category">
                              <a href="/category/sebastian-professional/color" title="Color">Color</a>
                            </div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302260092600003832" title="Cellophanes">Cellophanes</a>
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <ul className="sub-navigation-list ">
                              <li className="third-navigation-node">
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <ul className="sub-navigation-list ">
                              <li className="third-navigation-node">
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <ul className="sub-navigation-list ">
                              <li className="third-navigation-node last-child-navigation-list">
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="auto nav__links--primary nav__links--primary-has__sub js-enquire-has-sub mega-menu-item">
                    <span className=" yCmsComponent nav__link js_nav__link">
                      <a href="/nioxin" title="Nioxin">Nioxin<span className="glyphicon glyphicon-menu-down hidden-sm hidden-xs" /></a></span><span className="glyphicon  glyphicon-menu-right hidden-md hidden-lg nav__link--drill__down js_nav__link--drill__down" />
                    <div className="sub__navigation js_sub__navigation col-md-12" style={{top: '176.297px', marginTop: '0px'}}>
                      <a className="sm-back js-enquire-sub-close hidden-md hidden-lg" href="#">Back</a>
                      <div className="row">
                        <div className="container">
                          <div className="sub-navigation-section col-md-2">
                            <div className="yCmsComponent l2Category">
                              <a href="/category/nioxin-professional/care" title="Care">Care</a>
                            </div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nioxin-professional/care" title="All Care">All
                                  Care</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nioxin-professional/care/system-1-line-302270109100004804" title="System 1">System 1</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nioxin-professional/care/system-2" title="System 2">System 2</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nioxin-professional/care/system-3" title="System 3">System 3</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nioxin-professional/care/system-4" title="System 4">System 4</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nioxin-professional/care/system-5" title="System 5">System 5</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nioxin-professional/care/system-6" title="System 6">System 6</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nioxin-professional/care/scalp-recovery" title="Scalp Recovery">Scalp Recovery</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/nioxin-scalp-relief" title="Scalp Relief">Scalp Relief</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nioxin-professional/accessories/other-nioxin-professional" title="Accessories ">Accessories </a>
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <div className="yCmsComponent l2Category">
                              <a href="/category/nioxin-professional/expert-care" title="Expert Care">Expert Care</a>
                            </div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/BRAND_3022701095" title="All Expert Care">All Expert Care</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302270109100004803" title="Dermabrasion Treatment">Dermabrasion Treatment</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/product/scalp-protect-serum-6-x-8ml-/000000000081655136" title="Scalp Protect">Scalp Protect</a>
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <div className="yCmsComponent l2Category">
                              <a href="/category/nioxin-professional/intensive-care" title="Intensive Care">Intensive Care</a>
                            </div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/BRAND_3022701096" title="All Intensive Care">All Intensive Care</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302270092800003855" title="Night Density Rescue">Night Density Rescue</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302270092800003571" title="Recharging Complex">Recharging Complex</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302270109100004811" title="3D Intensive Diamax">3D Intensive Diamax</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302270109100004801" title="3D Intensive Hair Booster">3D Intensive Hair Booster</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nioxin-professional/intensive-care/deep-protect-density-mask" title="3D Intensive Deep Protect Density Mask">3D Intensive Deep
                                  Protect Density Mask</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nioxin-professional/intensive-care/density-defend" title="Density Defend Foam">Density Defend Foam</a>
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <div className="yCmsComponent l2Category">
                              <a href="/category/nioxin-professional/styling" title="Styling">Styling</a>
                            </div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nioxin-professional/styling/nioxin-styling" title="All Styling">All Styling</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/product/thickening-gel/000000099240107538" title="Thickening Gel">Thickening Gel</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/product/thickening-spray-/000000099240107459" title="Thickening Spray">Thickening Spray</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/product/therm-activ-protector/000000099350033997" title="Therm Activ Protector">Therm Activ Protector</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/product/instant-fullness-dry-shampoo/000000099350069388" title="Instant Fullness">Instant Fullness</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/product/bodifying-hair-foam/000000099350046797" title="Bodifying Hair Foam">Bodifying Hair Foam</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/product/niospray-strong-hold-hairspray/000000099240072324" title="Niospray Strong Hold">Niospray Strong Hold</a>
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <div className="yCmsComponent l2Category">
                              <a href="/category/nioxin-professional/advanced-thinning" title="Advanced Thinning & Regrowth">Advanced Thinning &amp;
                                Regrowth</a>
                            </div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/product/hair-regrowth-for-woman-30-day-supply-/000000099240010509" title="Hair Regrowth Treatment for Women">Hair Regrowth
                                  Treatment for Women</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/product/hair-regrowth-for-men-30-day-supply-/000000099240010508" title="Hair Regrowth Treatment for Men">Hair Regrowth Treatment
                                  for Men</a>
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <ul className="sub-navigation-list ">
                              <li className="third-navigation-node last-child-navigation-list">
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="auto nav__links--primary nav__links--primary-has__sub js-enquire-has-sub mega-menu-item">
                    <span className=" yCmsComponent nav__link js_nav__link">
                      <a href="/clairol-professional" title="Clairol Pro">Clairol Pro<span className="glyphicon glyphicon-menu-down hidden-sm hidden-xs" /></a></span><span className="glyphicon  glyphicon-menu-right hidden-md hidden-lg nav__link--drill__down js_nav__link--drill__down" />
                    <div className="sub__navigation js_sub__navigation col-md-12" style={{top: '176.297px', marginTop: '0px'}}>
                      <a className="sm-back js-enquire-sub-close hidden-md hidden-lg" href="#">Back</a>
                      <div className="row">
                        <div className="container">
                          <div className="sub-navigation-section col-md-2">
                            <div className="yCmsComponent l2Category">
                              <a href="https://us.wella.professionalstore.com/c/BRAND_3022400924" title="Color">Color</a>
                            </div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/shimmer-lights/color" title="Shimmer Lights">Shimmer
                                  Lights</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/clairol-open-line/lighteners/bw-lightener" title="BW Lightener">BW Lightener</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/clairol-open-line/lighteners/kaleidocolors" title="Kaleidocolors">Kaleidocolors</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302240092600000001" title="Advanced Gray Solution">Advanced Gray Solution</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302240092600000002" title="Beautiful Collection">Beautiful Collection</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/clairol-open-line/permanent-color/liquicolor-permanente-line-302240093000000001" title="Liquicolor Permanente">Liquicolor Permanente</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/clairol-open-line/permanent-color/creme-permanente-line-302240093000000002" title="Crème Permanente">Crème Permanente</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302240092500000001" title="Jazzing">Jazzing</a>
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <div className="yCmsComponent l2Category">
                              <a href="https://us.wella.professionalstore.com/c/BRAND_3022400924" title="Color Categories">Color Categories</a>
                            </div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/clairol-open-line/permanent-color-brand-3022400930" title="Permanent Color">Permanent Color</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/BRAND_3022400926" title="Semi-Permanent">Semi-Permanent</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/BRAND_3022400925" title="Temporary Color">Temporary Color</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/clairol-open-line/lighteners-brand-3022400928" title="Lightener">Lightener</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/BRAND_3022400927" title="Developer">Developer</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/clairol-open-line/toners-brand-3022400929" title="Toner">Toner</a>
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <div className="yCmsComponent l2Category">
                              <a href="/category/clairol-open-line/care/shimmer-lights" title="Care">Care</a>
                            </div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary last-child-navigation-list">
                                <a href="/shimmer-lights/care" title="Shimmer Lights">Shimmer
                                  Lights</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="auto nav__links--primary nav__links--primary-has__sub js-enquire-has-sub mega-menu-item">
                    <span className=" yCmsComponent nav__link js_nav__link">
                      <a href="/colorcharm" title="Colorcharm">Colorcharm<span className="glyphicon glyphicon-menu-down hidden-sm hidden-xs" /></a></span><span className="glyphicon  glyphicon-menu-right hidden-md hidden-lg nav__link--drill__down js_nav__link--drill__down" />
                    <div className="sub__navigation js_sub__navigation col-md-12" style={{top: '176.297px', marginTop: '0px'}}>
                      <a className="sm-back js-enquire-sub-close hidden-md hidden-lg" href="#">Back</a>
                      <div className="row">
                        <div className="container">
                          <div className="sub-navigation-section col-md-2">
                            <div className="yCmsComponent l2Category">
                              <a href="/category/color-charm/color-brand-3022500001" title="Color">Color</a>
                            </div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/colorcharm-toners" title="colorcharm Toner">colorcharm
                                  Toner</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/color-charm/permanent-color/permanent-liquid-line-302250000400000001" title="Permanent Liquid">Permanent Liquid</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/color-charm/permanent-color/permanent-gel-line-302250000400000002" title="Permanent Gel">Permanent Gel</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/color-charm/demi-permanent-color/demi-permanent-line-302250000500000001" title="Demi-Permanent">Demi-Permanent</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/color-charm/permanent-color/wella-colortango" title="WELLA colortango">WELLA colortango</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/color-charm/toners/wella-colortango-toner" title="WELLA colortango Toner">WELLA colortango Toner</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/color-charm/lighteners/lightener-line-302250000800000001" title="colorcharm Powder Lightener">colorcharm Powder
                                  Lightener</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/color-charm/semi-permanent-color/paints-line-302250000600000001" title="Paints">Paints</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/color-charm/lighteners/painting-balayage-lightener" title="Painting Balayage Lightener">Painting Balayage
                                  Lightener</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/color-charm/developer/painting-balayge-developer" title="Painting Balayage Developer">Painting Balayage
                                  Developer</a>
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <div className="title">Color Categories</div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/color-charm/permanent-color/permanent-gel-line-302250000400000002" title="Permanent Gel Color">Permanent Gel Color</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/color-charm/permanent-color/permanent-liquid-line-302250000400000001" title="Permanent Liquid Color">Permanent Liquid Color</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/color-charm/demi-permanent-color/demi-permanent-line-302250000500000001" title="Demi-Permanent Color">Demi-Permanent Color</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/BRAND_3022500002" title="Developer">Developer</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/color-charm/lighteners-brand-3022500008" title="Lightener">Lightener</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary last-child-navigation-list">
                                <a href="/category/color-charm/developer/activator-line-302250000200000006" title="Activator">Activator</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="auto nav__links--primary nav__links--primary-has__sub js-enquire-has-sub mega-menu-item">
                    <span className=" yCmsComponent nav__link js_nav__link">
                      <a href="/opi" title="OPI">OPI<span className="glyphicon glyphicon-menu-down hidden-sm hidden-xs" /></a></span><span className="glyphicon  glyphicon-menu-right hidden-md hidden-lg nav__link--drill__down js_nav__link--drill__down" />
                    <div className="sub__navigation js_sub__navigation col-md-12" style={{top: '176.297px', marginTop: '0px'}}>
                      <a className="sm-back js-enquire-sub-close hidden-md hidden-lg" href="#">Back</a>
                      <div className="row">
                        <div className="container">
                          <div className="sub-navigation-section col-md-2">
                            <div className="yCmsComponent l2Category">
                              <a href="/category/nail-color/nail-color" title="Nail Color">Nail
                                Color</a>
                            </div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nail-color/gelcolor" title="GelColor">GelColor</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nail-color/infinite-shine" title="Infinite Shine">Infinite Shine</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nail-color/nail-lacquer" title="Nail Lacquer">Nail Lacquer</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/opi/nature-strong" title="Vegan Natural Nail Polish">Vegan
                                  Natural Nail Polish</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nail-color/opi-dipping-powder-perfection" title="Powder Perfection">Powder Perfection</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/opi/acrylics" title="Acrylics">Acrylics</a>
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <div className="yCmsComponent l2Category">
                              <a href="/category/nail-care/nail-care" title="Nail Care">Nail Care</a>
                            </div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nail-care/nail-care" title="All Nail Care">All
                                  Nail Care</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/opi/nail-envy" title="Nail Envy">Nail Envy</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/treatments-strengtheners/treatments-strengtheners" title="Treatments & Strengtheners">Treatments &amp;
                                  Strengtheners</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/top-base-coats/top-base-coats" title="Top & Base Coats">Top &amp; Base Coats</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/sanitation-disinfection/sanitation-disinfection" title="Sanitation & Disinfection">Sanitation &amp;
                                  Disinfection</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nail-care/hands-feet-skincare" title="Hands & Feet Skincare">Hands &amp; Feet Skincare</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/opi/drying-agents-finishers-brand-1016300561" title="Drying Agents & Finishers">Drying Agents &amp;
                                  Finishers</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/opi/adhesives-tips-brand-1016300564" title="Adhesives & Tips">Adhesives &amp; Tips</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/opi/polish-remover-brand-1016300560" title="Polish Remover">Polish Remover</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/opi/salon-accessories-brand-1016300562" title="Salon Accessories">Salon Accessories</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/opi/nail-tools" title="Tools">Tools</a>
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <div className="title">Color Collections</div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/opi/fall-wonders-collection" title="Fall Wonders">Fall Wonders</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/opi/power-of-hue-collection" title="Power of Hue">Power of Hue</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/opi/xbox-collection" title="XBox Collection">XBox
                                  Collection</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/opi/velvet-vision-collection" title="Velvet Vision Collection">Velvet Vision Collection</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/opi/nature-strong" title="Nature Strong">Nature Strong</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/opi/downtown-los-angeles" title="Downtown Los Angeles">Downtown Los Angeles</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/opi/malibu-collection" title="Malibu Collection">Malibu Collection</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/opi/hollywood-collection" title="Hollywood">Hollywood</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/opi/high-definition-glitters-collection" title="High Definition Glitters">High Definition Glitters</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/opi/muse-of-milan" title="Muse of Milan">Muse of
                                  Milan</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/opi/classics" title="Classics">Classics</a>
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <div className="title">Gifts</div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nail-color/gift-sets" title="OPI Gift Sets">OPI
                                  Gift Sets</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary last-child-navigation-list">
                                <a href="https://gifts.opi.com/" title="Personalized Gifts" target="_blank" rel="noopener noreferrer">Personalized Gifts</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="auto nav__links--primary nav__links--primary-has__sub js-enquire-has-sub mega-menu-item">
                    <span className=" yCmsComponent nav__link js_nav__link">
                      <a href="https://us.wella.professionalstore.com/category/wella-professionals/permanent-color/koleston-perfect-me-plus?sort=default&view=quickorder" title="Quick Order">Quick Order<span className="glyphicon glyphicon-menu-down hidden-sm hidden-xs" /></a></span><span className="glyphicon  glyphicon-menu-right hidden-md hidden-lg nav__link--drill__down js_nav__link--drill__down" />
                    <div className="sub__navigation js_sub__navigation col-md-12" style={{top: '176.297px', marginTop: '0px'}}>
                      <a className="sm-back js-enquire-sub-close hidden-md hidden-lg" href="#">Back</a>
                      <div className="row">
                        <div className="container">
                          <div className="sub-navigation-section col-md-2">
                            <div className="title">Wella Color</div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/permanent-color/koleston-perfect-me-plus?sort=default&view=quickorder" title="Koleston Perfect">Koleston Perfect</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302200088900003532?sort=default&view=quickorder" title="Illumina Color">Illumina Color</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302200089800003541?sort=default&view=quickorder" title="Color Perfect">Color Perfect</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302200089100003912?sort=default&view=quickorder" title="Color Touch">Color Touch</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/category/wella-professionals/demi-permanent-color/shinefinity-zero-lift-glaze?sort=default&view=quickorder" title="Shinefinity">Shinefinity</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302200089900003542?sort=default&view=quickorder" title="Midway Couture">Midway Couture</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/BRAND_3022000897?sort=name-asc&view=quickorder" title="Wellaplex">Wellaplex</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302200089200003535?sort=default&view=quickorder" title="Color Fresh">Color Fresh</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302200089200004629?sort=name-asc&view=quickorder" title="Color Fresh Create">Color Fresh Create</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302200089600003539?sort=name-asc&view=quickorder" title="Color Renew">Color Renew</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302200109500004819?sort=name-asc&view=quickorder" title="Insta Recharge">Insta Recharge</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/BRAND_3022000894?sort=default&view=quickorder" title="Developers">Developers</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/category/wella-professionals/lightener/category/wella-professionals/lightener?sort=name-asc&view=quickorder" title="Lightener">Lightener</a>
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <div className="title">Wella Care &amp; Styling</div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/en/Wella-Professionals/Care/c/BRAND_3022000901?sort=name-asc&view=quickorder" title="All Care">All Care</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/en/Wella-Professionals/Styling/c/BRAND_3022000904?sort=name-asc&view=quickorder" title="All Styling">All Styling</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/en/Wella-Professionals/Care/INVIGO-Brilliance/c/LINE_302200090100003941?sort=name-asc&view=quickorder" title="INVIGO Brilliance">INVIGO Brilliance</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/en/Wella-Professionals/Care/INVIGO-Volume-Boost%C2%A0/c/LINE_302200090100003942?sort=name-asc&view=quickorder" title="INVIGO Volume Boost">INVIGO Volume Boost</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/en/Wella-Professionals/Care/INVIGO-Balance/c/LINE_302200090100003944?sort=name-asc&view=quickorder" title="INVIGO Balance">INVIGO Balance</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/en/Wella-Professionals/Care/Blonde-Recharge/c/LINE_302200090100003943?sort=name-asc&view=quickorder" title="INVIGO Blonde Recharge">INVIGO Blonde Recharge</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/en/Wella-Professionals/Care/FusionPlex/c/LINE_302200090100003926?sort=name-asc&view=quickorder" title="FUSIONPLEX">FUSIONPLEX</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/en/Wella-Professionals/Care/Oil-Reflections/c/LINE_302200090100003927?sort=name-asc&view=quickorder" title="Oil Reflections">Oil Reflections</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/en/Wella-Professionals/Care/Elements/c/LINE_302200090100003928?sort=name-asc&view=quickorder" title="Elements">Elements</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/c/LINE_302200090100003921?sort=name-asc&view=quickorder" title="Service">Service</a>
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <div className="title">Sebastian Professional</div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/en/Sebastian-Professional/c/HOUSE_30226?sort=name-asc&view=quickorder" title="All Sebastian">All Sebastian</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/en/Sebastian-Professional/Styling/c/BRAND_3022600926?sort=name-asc&view=quickorder" title="Sebastian Styling">Sebastian Styling</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/en/Sebastian-Professional/Care/c/BRAND_3022601090?sort=name-asc&view=quickorder" title="Sebastian Care">Sebastian Care</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/en/Sebastian-Professional/Color/Cellophanes/c/LINE_302260092600003832?sort=name-asc&view=quickorder" title="Sebastian Color">Sebastian Color</a>
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <div className="title">NIOXIN</div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/en/Nioxin/c/HOUSE_30227?sort=name-asc&view=quickorder" title="All NIOXIN ">All NIOXIN </a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/en/Nioxin/Care/System-1/c/LINE_302270109100004804?sort=name-asc&view=quickorder" title="System 1">System 1</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/en/Nioxin/Care/System-2/c/LINE_302270109100004805?sort=name-asc&view=quickorder" title="System 2">System 2</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/en/Nioxin/Care/System-3/c/LINE_302270109100004806?sort=name-asc&view=quickorder" title="System 3">System 3</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/en/Nioxin/Care/System-4/c/LINE_302270109100004807?sort=name-asc&view=quickorder" title="System 4">System 4</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/en/Nioxin/Care/System-5/c/LINE_302270109100004808?sort=name-asc&view=quickorder" title="System 5">System 5</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/en/Nioxin/Care/System-6/c/LINE_302270109100004809?sort=name-asc&view=quickorder" title="System 6">System 6</a>
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <div className="yCmsComponent l2Category">
                              <a href="https://us.wella.professionalstore.com/category/nail-color/gelcolor?sort=name-asc&view=quickorder" title="OPI">OPI</a>
                            </div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/category/nail-color/gelcolor?sort=name-asc&view=quickorder" title="GelColor">GelColor</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/category/nail-color/infinite-shine?sort=name-asc&view=quickorder" title="Infinite Shine">Infinite Shine</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/category/nail-color/nail-lacquer?sort=name-asc&view=quickorder" title="Nail Lacquer">Nail Lacquer</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/category/nail-color/opi-dipping-powder-perfection?sort=name-asc&view=quickorder" title="Powder Perfection">Powder Perfection</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/category/nail-care/nail-care?sort=name-asc&view=quickorder" title="All Nail Care">All Nail Care</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/category/treatments-strengtheners/treatments-strengtheners?sort=name-asc&view=quickorder" title="Treatments & Strengtheners">Treatments &amp;
                                  Strengtheners</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/category/top-base-coats/top-base-coats?sort=name-asc&view=quickorder" title="Top & Base Coats">Top &amp; Base Coats</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/category/sanitation-disinfection/sanitation-disinfection?sort=name-asc&view=quickorder" title="Sanitation & Disinfection">Sanitation &amp;
                                  Disinfection</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary last-child-navigation-list">
                                <a href="https://us.wella.professionalstore.com/category/nail-color/gift-sets?sort=name-asc&view=quickorder" title="Gift Sets">Gift Sets</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="auto nav__links--primary nav__links--primary-has__sub js-enquire-has-sub mega-menu-item">
                    <span className=" yCmsComponent nav__link js_nav__link">
                      <a href="/promos" title="Sale">Sale<span className="glyphicon glyphicon-menu-down hidden-sm hidden-xs" /></a></span><span className="glyphicon  glyphicon-menu-right hidden-md hidden-lg nav__link--drill__down js_nav__link--drill__down" />
                    <div className="sub__navigation js_sub__navigation col-md-12" style={{top: '176.297px', marginTop: '0px'}}>
                      <a className="sm-back js-enquire-sub-close hidden-md hidden-lg" href="#">Back</a>
                      <div className="row">
                        <div className="container">
                          <div className="sub-navigation-section col-md-2">
                            <ul className="sub-navigation-list ">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/promos" title="All Promotions">All Promotions</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/promos#wella" title="Wella Promos">Wella Promos</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/promos#sebastian" title="Sebastian Promos">Sebastian Promos</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/promos#nioxin" title="Nioxin Promos">Nioxin Promos</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/promos#clairol" title="Clairol Promos">Clairol Promos</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/promos#colorcharm" title="Colorcharm Promos">Colorcharm Promos</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary last-child-navigation-list">
                                <a href="https://us.wella.professionalstore.com/promos#last-chance" title="Last Chance">Last Chance</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="auto nav__links--primary nav__links--primary-has__sub js-enquire-has-sub mega-menu-item">
                    <span className=" yCmsComponent nav__link js_nav__link">
                      <a href="/consumers" title="Consumers">Consumers<span className="glyphicon glyphicon-menu-down hidden-sm hidden-xs" /></a></span><span className="glyphicon  glyphicon-menu-right hidden-md hidden-lg nav__link--drill__down js_nav__link--drill__down" />
                    <div className="sub__navigation js_sub__navigation col-md-12" style={{top: '176.297px', marginTop: '0px'}}>
                      <a className="sm-back js-enquire-sub-close hidden-md hidden-lg" href="#">Back</a>
                      <div className="row">
                        <div className="container">
                          <div className="sub-navigation-section col-md-2">
                            <div className="yCmsComponent l2Category">
                              <a href="/WellaCareLP" title="Wella Care">Wella Care</a>
                            </div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/care-brand-3022000901" title="All Care">All Care</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/color-fresh-masks" title="Color Fresh Masks">Color Fresh
                                  Masks</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/invigo" title="All INVIGO">All INVIGO</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/care/invigo-brilliance" title="INVIGO Brilliance">INVIGO Brilliance</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/care/invigo-nutri-enrich-line-302200090100003945" title="INVIGO Nutri-Enrich">INVIGO Nutri-Enrich</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/care/invigo-volume-boost-" title="INVIGO Volume Boost">INVIGO Volume Boost</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/care/blonde-recharge" title="INVIGO Blonde Recharge">INVIGO Blonde Recharge</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/care/invigo-balance-line-302200090100003944" title="INVIGO Balance">INVIGO Balance</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/care/fusionplex" title="FUSIONPLEX">FUSIONPLEX</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/care/colormotion-plus" title="ColorMotion+">ColorMotion+</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/care/oil-reflections" title="Oil Reflections">Oil Reflections</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/care/elements" title="Elements">Elements</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/care/nutricurls-line-302200090100003946" title="Nutricurls">Nutricurls</a>
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <div className="yCmsComponent l2Category">
                              <a href="/category/wella-professionals/styling-brand-3022000904" title="EIMI">EIMI</a>
                            </div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/styling-brand-3022000904" title="All EIMI">All EIMI</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/styling/eimi-hairsprays" title="Hairspray">Hairspray</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/styling/eimi-volume" title="Volume">Volume</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/styling/eimi-smooth" title="Smooth">Smooth</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/styling/eimi-texture" title="Texture">Texture</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/styling/nutricurls" title="Nutricurls">Nutricurls</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/wella-professionals/styling/eimi-shine" title="Shine">Shine</a>
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <div className="yCmsComponent l2Category">
                              <a href="/category/sebastian-professional/sebastian-professional-house-30226" title="Sebastian">Sebastian</a>
                            </div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/sebastian-professional/sebastian-professional-house-30226" title="All Sebastian">All Sebastian</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/sebastian-professional/care-brand-3022601090" title="Care">Care</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/sebastian-professional/styling-brand-3022600926" title="Styling">Styling</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/sebastian-professional/styling/hairspray" title="Hairspray">Hairspray</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/sebastian-professional/styling/flow" title="Nourish">Nourish</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/sebastian-professional/styling/form" title="Texture">Texture</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/sebastian-professional/styling/flex" title="Curls">Curls</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/sebastian-professional/care/drench" title="Drench">Drench</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/sebastian-professional/styling/dark-oil-line" title="Dark Oil">Dark Oil</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/sebastian-professional/care/reset-preset" title="Reset Preset">Reset Preset</a>
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <div className="yCmsComponent l2Category">
                              <a href="/category/nioxin-professional/nioxin" title="Nioxin">Nioxin</a>
                            </div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nioxin-professional/nioxin" title="All Nioxin">All Nioxin</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nioxin-professional/care" title="Care">Care</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nioxin-professional/styling" title="Styling">Styling</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nioxin-professional/advanced-thinning" title="Advanced Thinning">Advanced Thinning</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nioxin-professional/intensive-care" title="Intensive Care">Intensive Care</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nioxin-professional/supplements" title="Supplements">Supplements</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nioxin-professional/care/system-1-line-302270109100004804" title="System 1">System 1</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nioxin-professional/care/system-2" title="System 2">System 2</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nioxin-professional/care/system-3" title="System 3">System 3</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nioxin-professional/care/system-4" title="System 4">System 4</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nioxin-professional/care/system-5" title="System 5">System 5</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nioxin-professional/care/system-6" title="System 6">System 6</a>
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <div className="yCmsComponent l2Category">
                              <a href="/category/clairol-open-line/clairol-professional-house-30224" title="Clairol">Clairol</a>
                            </div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/clairol-open-line/clairol-professional-house-30224" title="All Clairol">All Clairol</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/shimmer-lights/color" title="Shimmer Lights Color">Shimmer
                                  Lights Color</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/clairol-open-line/care/shimmer-lights" title="Shimmer Lights Care">Shimmer Lights Care</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/clairol-open-line/permanent-color-brand-3022400930" title="Permanent Color">Permanent Color</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/clairol-open-line/semi-permanent" title="Semi-Permanent Color">Semi-Permanent Color</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/clairol-open-line/temporary-color-brand-3022400925" title="Temporary Color">Temporary Color</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/clairol-open-line/color/cp-lightener" title="Lightener">Lightener</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/clairol-open-line/developer-brand-3022400927" title="Developer">Developer</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/clairol-open-line/color/permanent-liquid-line-302240092400000001" title="Permanent Liquid">Permanent Liquid</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/clairol-open-line/semi-permanent/beautiful-collection-line-302240092600000002" title="Beautiful Collection">Beautiful Collection</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/clairol-open-line/semi-permanent/advanced-gray-solution" title="Advanced Gray Solution">Advanced Gray Solution</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/clairol-open-line/temporary-color/jazzing-line-302240092500000001" title="Jazzing">Jazzing</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/clairol-open-line/developer/pure-white" title="Pure White">Pure White</a>
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <div className="yCmsComponent l2Category">
                              <a href="/category/color-charm/color-charm-house-30225" title="Wella colorcharm">Wella colorcharm</a>
                            </div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/color-charm/color-charm-house-30225" title="All colorcharm">All colorcharm</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/color-charm/toners-brand-3022500007" title="Toner">Toner</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/color-charm/lighteners-brand-3022500008" title="Lightener">Lightener</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/color-charm/permanent-color/permanent-liquid-line-302250000400000001" title="Permanent Liquid">Permanent Liquid</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/color-charm/permanent-color/permanent-gel-line-302250000400000002" title="Permanent Gel">Permanent Gel</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/color-charm/demi-permanent-color-brand-3022500005" title="Demi-Permanent">Demi-Permanent</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/color-charm/permanent-color/wella-colortango" title="WELLA colortango">WELLA colortango</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/color-charm/toners/wella-colortango-toner" title="WELLA colortango Toner">WELLA colortango Toner</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/color-charm/semi-permanent-color/paints-line-302250000600000001" title="Paints">Paints</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="https://us.wella.professionalstore.com/search/?q=PAINTING%3Arelevance%3ALine%3ALINE_302250000200000005%3ALine%3ALINE_302250000800000002&text=PAINTING&text-count=2#" title="Painting Balayage System">Painting Balayage System</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/color-charm/developer-brand-3022500002" title="Developer">Developer</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/color-charm/color/activator-line-302250000100000003" title="Activator">Activator</a>
                              </li>
                            </ul>
                          </div>
                          <div className="sub-navigation-section col-md-2">
                            <div className="yCmsComponent l2Category">
                              <a href="/opi" title="OPI">OPI</a>
                            </div>
                            <ul className="sub-navigation-list has-title">
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nail-color/nail-lacquer" title="Nail Lacquer">Nail Lacquer</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nail-color/infinite-shine" title="Infinite Shine">Infinite Shine</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/opi/nature-strong" title="Vegan Natural Nail Polish">Vegan
                                  Natural Nail Polish</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/top-base-coats/top-base-coats" title="Top & Base Coats">Top &amp; Base Coats</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/treatments-strengtheners/treatments-strengtheners" title="Treatments & Strengtheners">Treatments &amp;
                                  Strengtheners</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nail-care/hands-feet-skincare" title="Hands & Feet Skincare">Hands &amp; Feet Skincare</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nail-care/drying-agents-finishers-prodsubcat-111408" title="Drying Agents & Finishers">Drying Agents &amp;
                                  Finishers</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/nail-care/polish-remover" title="Polish Remover">Polish Remover</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary">
                                <a href="/category/opi/nail-tools" title="Tools">Tools</a>
                              </li>
                              <li className="yCmsComponent nav__link--secondary last-child-navigation-list">
                                <a href="/category/nail-color/gift-sets" title="Gift Sets">Gift
                                  Sets</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <div className="js-country-selector-menu">
                  <div className="countrySelector col-xs-12 text-center">
                    <div className="footer__dropdown">
                      <div className="form-group">
                        <div className="btn-group dropup lang-selector"><button aria-expanded="false" aria-haspopup="true" className="form-control dropdown-toggle" data-toggle="dropdown" type="button"><img alt="" className="lang-selector-planet" src="https://us.wella.professionalstore.com/_ui/responsive/theme-cotyThemeAT/images/planet.svg" />
                            Change
                            country</button>
                          <ul className="dropdown-menu">
                            <li><a href="https://us.wella.professionalstore.com">US</a></li>
                            <li><a href="https://ca.wella.professionalstore.com">Canada</a></li>
                            <li><a href="https://be.wella.professionalstore.com/?lang=nl_BE">Belgium</a>
                            </li>
                            <li><a href="https://be.wella.professionalstore.com/?lang=fr_BE">Belgique</a>
                            </li>
                            <li><a href="https://dk.wella.professionalstore.com">Denmark</a></li>
                            <li><a href="https://de.wella.professionalstore.com">Deutschland</a></li>
                            <li><a href="https://es.wella.professionalstore.com">España</a></li>
                            <li><a href="https://fi.wella.professionalstore.com">Finland</a></li>
                            <li><a href="https://fr.wella.professionalstore.com">France</a></li>
                            <li><a href="https://gr.wella.professionalstore.com">Ελλάδα</a></li>
                            <li><a href="https://ie.wella.professionalstore.com">Ireland</a></li>
                            <li><a href="https://it.wella.professionalstore.com">Italia</a></li>
                            <li><a href="https://be.wella.professionalstore.com/?lang=fr_BE">Luxembourg</a>
                            </li>
                            <li><a href="https://nl.wella.professionalstore.com">Nederland</a></li>
                            <li><a href="https://no.wella.professionalstore.com">Norge</a></li>
                            <li><a href="https://at.wella.professionalstore.com">Österreich</a></li>
                            <li><a href="https://pt.wella.professionalstore.com">Portugal</a></li>
                            <li><a href="https://uk.wella.professionalstore.com">United Kingdom</a></li>
                            <li><a href="https://ch.wella.professionalstore.com/?lang=de_CH">Schweiz</a>
                            </li>
                            <li><a href="https://ch.wella.professionalstore.com/?lang=fr_CH">Suisse</a>
                            </li>
                            <li><a href="https://se.wella.professionalstore.com">Sverige</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
          <div className="breadcrumb-container">
            <div className="container breadcrumb-section showbreadcrumb">
              <ol className="breadcrumb">
                <li>
                  <a href="/">Home</a>
                </li>
                <li className="active">Sign In</li>
              </ol>
            </div>
          </div>
          <div className="container hidden-xs hidden-sm js-secondaryNavAccount collapse" id="accNavComponentDesktopOne">
            <ul className="nav__links">
            </ul>
          </div>
          <div className="container hidden-xs hidden-sm js-secondaryNavCompany collapse" id="accNavComponentDesktopTwo">
            <ul className="nav__links js-nav__links">
            </ul>
          </div>
        </header>
      </div>
    );
  }
  
  export default Header;
  
